const vn = {
  lang: {
    vietnam: "Tiếng Việt",
    english: "English",
    japannese: "日本語",
  },
  header: {
    home: "Trang chủ",
    about_us: "Về chúng tôi",
    why_us: "Con người",
    services: "Dịch vụ",
    projects: "Dự án",
    projectsOther: "Dự án khác",
    activities: "Hoạt động",
    contact: "Liên hệ",
    recruiment: "Tuyển dụng",
    product: "Sản phẩm",
    blog: "Blog",
    news: "Tin tức",
    selectLang: "Chọn ngôn ngữ",
  },
  seo: {
    home: {
      title: "Trang chủ | TGL Solutions - Smart & Speed Solution",
      description:
        'Với phương châm "Xây dựng công ty vững mạnh lấy con người làm trung tâm", mọi chính sách, quyết định của công ty đều hướng đến xây dựng một môi trường làm việc thoải mái nhất, tạo mọi điều kiện để nhân viên phát huy hết tiềm năng, thế mạnh bản thân và định hướng sự nghiệp.',
      keywords:
        "TGL Solution, công ty phần mềm, giải pháp phần mềm, công nghệ tiên tiến, ứng dụng web, ứng dụng di động, AI, Metaverse, AutoCAD, Website, Application, Mobile",
    },
    about_us: {
      title: "Về chúng tôi | TGL Solutions - Smart & Speed Solution",
      description: "Nâng tầm giá trị con người Việt!",
    },
    why_us: {
      title: "Con người | TGL Solutions - Smart & Speed Solution",
      description:
        "Câu chuyện về những chú chim cánh cụt “tiên phong” của TGL Soluitons",
    },
    product: {
      title: "Dự án | TGL Solutions - Smart & Speed Solution",
      description:
        "TGL Solutions cam kết hoàn thành các dự án với chất lượng đảm bảo và thời gian nhanh chóng nhất, đáp ứng đúng theo yêu cầu của khách hàng. Danh sách các khách hàng lặp lại của công ty liên tục tăng.",
    },
    contact: {
      title: "Liên hệ | TGL Solutions - Smart & Speed Solution",
      description:
        "Đồng hành cùng TGL trở thành chú chim tiên phong tạo nên giá trị!",
    },
    recruiment: {
      title: "Tuyển dụng | TGL Solutions - Smart & Speed Solution",
      description: "Bắt đầu cuộc hành trình của bạn với TGL Solutions nào!",
    },
  },
  decoration_house: {
    title: "Hệ thống AI dùng trong thiết kế ngôi nhà",
    subtitle:
      "Thiết kế lại không gian sống của bạn theo hướng sáng tạo bằng hệ thống AI.",
    optionDecoration: "Chọn tùy chọn",
    labelUpload: "Tải hình ảnh",
    labelSample: "Chọn ví dụ",
    exterior: "Ngoại thất",
    interior: "Nội thất",
    theme: "Chọn chủ đề",
    number: "Chọn số lượng ảnh trả về",
    btnAi: "Tạo ảnh",
    note: "Chú ý: Càng nhiều ảnh, tốc độ xử lý càng chậm.",
    label: "Tùy chỉnh thiết kế",
    result: "Hình ảnh sau khi AI thiết kế",
    contentChoosePic: "HOẶC",
    queueBefore: "Hiện đang có",
    queueAfter: "người ở phía trước bạn, xin vui lòng chờ trong giây lát.",
    labelCountdown: "Kết quả sẽ có sau",
    inProgress: "Hệ thống đang xử lý hình ảnh của bạn",
    back: "Quay lại",
    upload: "Tải ảnh",
    close: "Đóng",
    download: "Tải xuống",
    zoom: "Phóng to",
    origin: "Ảnh gốc",
    output: "Sau khi thiết kế",
    reset: "Làm mới",
    step1: "Bước 1. ",
    step2: "Bước 2. ",
    step3: "Bước 3. ",
    step4: "Bước 4. ",
    drag: "Kéo để trải nghiệm",
    ex: "Ví dụ: ",
  },
  decoration_house_4season: {
    title: "Thiết kế ngoại thất ngôi nhà từ bản phác thảo",
    subtitle: "Sáng tạo theo phong cách bạn muốn với phối cảnh bốn mùa",
    optionDecoration: "Chọn tùy chọn",
    labelUpload: "Tải hình ảnh",
    labelSample: "Bạn có thể chọn ví dụ ở dưới đây:",
    exterior: "Ngoại thất",
    interior: "Nội thất",
    theme: "Nhập chủ đề",
    number: "Chọn số lượng ảnh trả về",
    btnAi: "Tạo ảnh",
    note: "Chú ý: Càng nhiều ảnh, tốc độ xử lý càng chậm.",
    label: "Tùy chỉnh thiết kế",
    result: "Hình ảnh sau khi AI thiết kế",
    contentChoosePic: "HOẶC",
    queueBefore: "Hiện đang có",
    queueAfter: "người ở phía trước bạn, xin vui lòng chờ trong giây lát.",
    labelCountdown: "Kết quả sẽ có sau",
    inProgress: "Hệ thống đang xử lý hình ảnh của bạn",
    back: "Quay lại",
    upload: "Tải ảnh",
    close: "Đóng",
    download: "Tải xuống",
    zoom: "Phóng to",
    origin: "Ảnh gốc",
    output: "Sau khi thiết kế",
    reset: "Làm mới",
    step1: "Bước 1. ",
    step2: "Bước 2. ",
    step3: "Bước 3. ",
    step4: "Bước 4. ",
    drag: "Kéo để trải nghiệm",
    ex: "Ví dụ: ",
    spring: "Xuân",
    summer: "Hạ",
    autumn: "Thu",
    winter: "Đông",
    numFloor: "Số tầng",
    errInput: "Giá trị không hợp lệ",
  },
  video: {
    cad: {
      title: "Dự án AUTOCAD",
      sub: "Đây là bản demo phát triển Command giúp tối ưu hóa và nâng cao năng suất cho vệc thiết kế và xây dựng công trình đường ống.",
    },
    revit: {
      title: "Dự án REVIT",
      sub: "Đây là bản demo sắp xếp các thanh bar tự động. Nó có thể cải thiện đáng kể công việc thiết kế của bạn.",
    },
    aiHouse: {
      title: "Thiết kế ngôi nhà bằng trí tuệ nhân tạo",
      sub: "Trong vài giây, hệ thống AI sẽ thiết kế lại và đem đến trải nghiệm mới mẻ vào không gian sống của bạn, dựa trên tùy chọn của bạn. Bạn có thể dùng thử ảnh để tham khảo ý tưởng mới, sau đó cân nhắc tải lên ảnh ngôi nhà của bạn.",
      button: "Thử trải nghiệm miễn phí",
    },
  },
  button: {
    show: "Đọc thêm",
    hide: "Ẩn bớt",
    seeMore: "Xem chi tiết",
  },
  ceo: {
    name: "CEO Lê Tiến Triển",
    title_image_1: "CEO Lê Tiến Triển",
    title_image_2: "Ông Triển nhận bằng tiến sĩ ở Nhật vào tháng 3-2018.",
    ceo_content_1:
      "TGL Solutions được thành lập bởi CEO, Tiến sĩ Lê Tiến Triển vào tháng 9-2019. Mang trong mình tinh thần và nhiệt huyết của con người Việt Nam, ông Lê Tiến Triển muốn cống hiến cho sự phát triển của xã hội thông qua TGL Solutions. Cũng chính vì vậy mà slogan của công ty là Smart & Speed Solutions, nghĩa là tạo ra các giải pháp (solutions) thông minh nhất (smart) và với tốc độ nhanh nhất (speed) để kịp thời cống hiến giá trị của công ty cho đất nước và cho xã hội.",
    ceo_content_2:
      "Vì sao trong thời đại công nghiệp 4.0 ngày nay, Smart và Speed lại rất quan trọng?",
    ceo_content_3:
      "Chúng ta đang rất may mắn được sống trong kỷ nguyên vàng của công nghệ thông tin (IT). Kỷ nguyên vàng này bắt đầu từ sự ra đời và phổ cập của Internet (năm 1995), và lịch sử cho thấy những kỷ nguyên vàng thường kéo dài trong vòng 50 năm. Nghĩa là chúng ta đang ở gần chính giữa của kỷ nguyên vàng này.",
    ceo_content_4:
      "Đầu những năm 2010, sự phát triển vượt bậc của trí tuệ nhân tạo (AI) đã đem đến cho con người những kĩ thuật mà dường như trước đó chỉ xuất hiện trong phim khoa học viễn tưởng. Các cỗ máy AI bây giờ có thể phân loại và nhận diện ảnh với độ chính xác cực cao và nhanh chóng  thậm chí còn hơn cả con người. AI đã và đang len lỏi vào khắp các lĩnh vực của đời sống hằng ngày của chúng ta như: giúp chúng ta chụp ảnh trên điện thoại thông minh tốt hơn, xử lí vận chuyển cho các đơn hàng để được chuyển phát nhanh hơn, ...",
    ceo_content_5:
      "AI bây giờ đang được bình dân hóa nhờ sự phát triển của Cloud. Nghĩa là ai cũng có thể sử dụng được AI. Điều đó dẫn tới hiệu suất công việc trong nhiều lĩnh vực được cải thiện một cách đáng kể. Nghĩa là bản thân con người chúng ta phải làm việc thông minh hơn (smart hơn) nếu không muốn bị bỏ lại phía sau. Đó chính là lí do của chữ “Smart” trong Smart & Speed Solutions.",
    ceo_content_6:
      "Còn về “Speed” thì sao? Nếu bây giờ bạn đang sử dụng 1 chiếc điện thoại thông minh, thì tôi xin chúc mừng bạn. Bạn đang sở hữu một siêu máy tính ở thời điểm cách đây khoảng 25 năm. Nghĩa là thứ mà mọi quốc gia đều mơ ước 25 năm trước, thì bây giờ ai cũng có thể sở hữu nó một cách dễ dàng hơn. Sự phát triển về khoa học kĩ thuật đang ngày càng nhanh chóng đến không thể ngờ tới. Và nếu như chúng ta không làm nhanh, sai lầm nhanh, sửa sai nhanh thì chúng ta sẽ bị tụt hậu lại phía sau. “Speed” không chỉ là tốc độ đưa ra các giải pháp, mà còn là tốc độ đưa ra các phán đoán, tốc độ sửa sai. Mắc sai lầm là con đường nhanh nhất để đi tới đáp án đúng nhất.",
    title_content_1: "Một số thành tích của CEO Lê Tiến Triển",
    array_achievements: [
      {
        id: 1,
        description:
          "Đạt giải nhì học sinh giỏi quốc gia môn Toán lớp 12, được tuyển thẳng vào đại học.",
        year: "Năm 2005",
      },
      {
        id: 2,
        description:
          "Đạt được học bổng của chính phủ Nhật (MEXT) đi du học ở Nhật.",
        year: "	Năm 2007",
      },
      {
        id: 3,
        description:
          "Được gia hạn học bổng MEXT 1 lần, gia hạn đặc biệt 2 lần, tổng cộng nhận học bổng MEXT trong 11 năm liền.",
        year: "Năm 2007 - 2018",
      },
      {
        id: 4,
        description:
          "Lấy bằng tiến sĩ ngành điện tử viễn thông và công nghệ thông tin ở Nhật.",
        year: "Năm 2018",
      },
      {
        id: 5,
        description:
          "Nghiên cứu viên cao cấp ở trung tâm nghiên cứu của tập đoàn Hitachi.",
        year: "Năm 2018 - 2021",
      },
      {
        id: 6,
        description:
          "Đạt nhiều giải thưởng dành cho thành tích nghiên cứu xuất sắc ở trường đại học, cũng như trong tập đoàn Hitachi.",
        year: "Năm 2013 - 2021",
      },
      {
        id: 7,
        description: "Có hơn 10 bằng sáng chế được công bố.",
        year: "Năm 2017 - 2021",
      },
      {
        id: 8,
        description:
          "Có nhiều bài báo khoa học được đăng ở các tạp chí quốc tế uy tín, đã từng đi dự hội nghị ở nhiều nước trên thế giới như Đức (2013), Canada (2014), Trung Quốc (2014, 2015, 2016), Pháp (2017), Mỹ (2019).",
        year: "Năm 2014 - 2019",
      },
    ],
    title_content_2: "Các cột mốc trong sự nghiệp của CEO Lê Tiến Triển",
    img_timeline: "/images/timeline-ceo.png",
    img_timeline_mobile: "/images/timeline-ceo-mobile.png",
    milestones_content: [
      "7-2019\nThành lập skyACE Co.Ltd (Trụ sở tại Tokyo, Nhật Bản)",
      "9-2019\nThành lập TGL Solutions, TP Hồ Chí Minh",
      "3-2022\nThành lập Prima Solutions TP Đà Nẵng,",
    ],
  },

  home: {
    banner: {
      subtitleStart: "Với phương châm",
      subtitleBold:
        "“Xây dựng Công ty vững mạnh, lấy con người làm trung tâm”.",
      subtitleEnd:
        "Mọi chính sách, quyết định của công ty đều hướng đến xây dựng môi trường làm việc thoải mái nhất, tạo mọi điều kiện để nhân viên phát huy hết tiềm năng, thế mạnh bản thân và định hướng nghề nghiệp.",
      btnContact: "Liên hệ chúng tôi",
    },
    process: {
      title: "Quy trình làm việc",
      cards: [
        {
          step: "Tiếp nhận yêu cầu",
          content: [
            "Lấy yêu cầu từ khách hàng",
            "Phân tích yêu cầu",
            "Thiết kế tài liệu, thiết kế giao diện",
          ],
        },
        {
          step: "Lập kế hoạch triển khai",
          content: [
            "Phân tích và xác định nguồn lực",
            "Lập kế hoạch và phân chia công việc",
          ],
        },
        {
          step: "Phát triển sản phẩm",
          content: [
            "Thiết kế chi tiết",
            "Thực hiện lập trình",
            "Thực hiện Unit test",
            "Project Leader kiểm tra, review source code",
          ],
        },
        {
          step: "Kiểm thử và sửa lỗi",
          content: [
            "Team QC thực hiện kiểm thử sản phẩm ở 3 giai đoạn: module testing, system testing, user acceptance testing",
            "Developer tiến hành sửa lỗi và hoàn thiện sản phẩm",
          ],
        },
        {
          step: "Bàn giao sản phẩm",
          content: [
            "Bàn giao sản phẩm cho khách hàng sau khi đã hoàn thiện",
            "Hỗ trợ vận hành và bảo trì",
          ],
        },
      ],
    },
    service: {
      title: "Dịch vụ",
      sub: "Từ khi thành lập cho đến nay, TGL Solutions luôn nỗ lực không ngừng để mở rộng và đa dạng hóa các dịch vụ công nghệ phần mềm, đáp ứng nhu cầu và xu thế phát triển của thị trường.",
      cards: [
        {
          category: "Web System",
          title: "Phát triển hệ thống Website",
          content:
            "Cung cấp giải pháp tư vấn toàn diện về thiết kế, lập trình và tối ưu website. Đặc biệt, có thể tích hợp các ứng dụng VR/ livestream/ đấu giá trực tiếp trên trang thương mại để tăng tương tác với khách hàng và hiệu quả thương mại.",
        },
        {
          category: "Metaverse",
          title: "Phát triển Metaverse",
          content:
            "Sử dụng các công nghệ mới (omniverse nvidia, actorcore,..) để mô phỏng phương thức hoạt động, dự đoán rủi ro của sản phẩm hoặc quy trình sản xuất, nhờ đó giảm thiểu phần lớn thời gian lắp đặt/sản xuất.",
        },
        {
          category: "AI",
          title: "Phát triển AI",
          content:
            "Ứng dụng công nghệ học chuyên sâu AI tiên tiến (DeepLabv3, HRnet) đóng góp cho y học, được kỳ vọng sẽ giúp chẩn đoán sớm bệnh tật. Từ đó, đưa ra phác đồ điều trị giúp kéo dài sự sống cho người bệnh và mang lại kết quả điều trị tốt hơn bao giờ hết.",
        },
        {
          category: "Smartphone App",
          title: "Phát triển ứng dụng điện thoại thông minh",
          content:
            "Với nhiều năm kinh nghiệm phát triển ứng dụng smartphone cho nền tảng iOS và Android, chúng tôi xây dựng nhiều loại ứng dụng cho khách hàng như: ứng dụng thương mại điện tử, mạng xã hội, ứng dụng quản lý doanh nghiệp.",
        },
        {
          category: "PC Application",
          title: "Phát triển ứng dụng PC",
          content:
            "Đặc trưng nổi bật của TGL chính là sẵn sàng nhanh chóng tạo ra bản demo để khách hàng phê duyệt. Điều này đã giúp chúng tôi liên tục cung cấp các giải pháp phát triển ứng dụng dựa trên các yêu cầu chất lượng mà khách hàng mong muốn.",
        },
        {
          category: "BIM/CAD",
          title: "Phát triển BIM/CAD",
          content:
            "Ứng dụng ngôn ngữ C/C# để tạo các lệnh trong bản vẽ thiết kế dân dụng, kiến ​​trúc và cơ khí. Tư vấn và hiệu chỉnh các tính năng bản vẽ CAD theo yêu cầu nhằm tối ưu hóa năng suất và hiệu quả làm việc công ty khách hàng.",
        },
        {
          category: "All",
          title: "Dự án của chúng tôi",
          content:
            "TGL Solutions cam kết hoàn thành các dự án với chất lượng đảm bảo và thời gian nhanh chóng nhất, đáp ứng đúng theo yêu cầu của khách hàng. Danh sách các khách hàng lặp lại của công ty liên tục tăng.",
        },
      ],
      button: "Xem chi tiết",
    },
    tglGroup: {
      title: "Mở rộng quy mô toàn cầu từ năm 2019",
      techStack: "Các công nghệ ",
      sub: "TGL Solutions tự hào là đối tác tin cậy của nhiều đơn vị khách hàng trong và ngoài nước với các giải pháp công nghệ chuyên nghiệp và hiệu quả, đúng như mong đợi của khách hàng.",
      content:
        "Môi trường làm việc năng động, nhiệt huyết, sáng tạo của những bạn trẻ",
      branch: [
        {
          name: "skyACE株式会社",
          sub: "Tọa lạc tại thủ đô Tokyo, Nhật Bản, skyACE (công ty mẹ của TGL Group) là nơi làm việc của nhiều chuyên gia giàu kinh nghiệm trong lĩnh vực công nghệ thông tin đến từ nhiều quốc gia trên thế giới.",
        },
        {
          name: "Prima Solutions",
          sub: "Thành lập vào tháng 4/2022, hướng tới mục tiêu chuyển đổi số trong ngành xây dựng, tập trung phát triển các ứng dụng công nghệ CAD/BIM trong lĩnh vực kiến ​​trúc, xây dựng và cơ khí.",
        },
        {
          name: "TGL Solutions",
          sub: "Đối mặt với nhiều thách thức từ khi thành lập, doanh thu và số lượng thành viên công ty vẫn tăng dần hằng năm. Năm 2022 doanh thu tăng 200% so với năm trước, đánh dấu cột mốc phát triển bền vững của TGL Solutions.",
        },
      ],
    },
    about: {
      title: "Về chúng tôi",
      sub: "TGL Solutions được thành lập vào tháng 9/2019 bởi Tiến sĩ Lê Tiến Triển. Với tinh thần và nhiệt huyết của con người Việt Nam, ông Lê Tiến Triển mong muốn được đóng góp cho sự phát triển xã hội thông qua TGL Solutions. Chính vì vậy slogan của công ty là Smart & Speed Solutions, nghĩa là tạo ra các giải pháp (Solutions) thông minh nhất (Smart) với tốc độ nhanh nhất (Speed), để kịp thời tạo ra giá trị tốt đẹp cho xã hội.",
      acthor: "CEO, Co-Founder, Tiến sĩ Lê Tiến Triển",
      btnView: "Xem thêm",
    },
    ourCertifications: {
      title: "Giải thưởng & Chứng nhận của chúng tôi",
    },
    project: {
      title: "Dự án của chúng tôi",
      sub: "TGL Solutions cam kết hoàn thành các dự án với chất lượng đảm bảo và thời gian nhanh chóng nhất, đáp ứng đúng theo yêu cầu của khách hàng. Danh sách các khách hàng lặp lại của công ty liên tục tăng.",
      btnView: "Xem tất cả dự án",
      clients: "Đối tác",
      companyProjects: "Dự án",
    },
    contact: {
      title: "Liên hệ chúng tôi nhé!",
      sub: {
        span: "Môi trường lí tưởng mà TGL Solutions đang hướng tới là nơi tất cả mọi người đều được thoải mái tạo ra những giá trị tuyệt vời, mang lại sự thay đổi tích cực cho sự phát triển bền vững của công ty và xã hội.",
        p: "",
      },
    },
    BIMCAD_highlight: {
      title: "Dự án BIMCAD nổi bật",
      button: "Xem demo",
      content1: {
        title: "Dự án PIPING",
        sub: "Đây là bản demo phát triển Command gốc và hiệu suất hóa cho việc thực hiện công trình đường ống.",
      },
      content2: {
        title: "Dự án REVIT",
        sub: "Đây là bản demo sắp xếp các thanh bar tự động. Nó có thể cải thiện đáng kể công việc thiết kế của bạn.",
      },
      content3: {
        title: "Thiết kế ngôi nhà bằng trí tuệ nhân tạo",
        sub: "Trong vài giây, hệ thống AI sẽ thiết kế lại và đem đến trải nghiệm mới mẻ vào không gian sống của bạn, dựa trên tùy chọn của bạn. Bạn có thể dùng thử ảnh để tham khảo ý tưởng mới, sau đó cân nhắc tải lên ảnh ngôi nhà của bạn.",
        button: "Trải nghiệm",
      },
    },
  },

  products: {
    webCloud: {
      title: "Hệ thống Web Cloud",
      items: [
        {
          content: "Work Bee - Nền tảng chuyển đổi số tỉnh gọn",
          link: "https://work-bee.net/",
        },
        {
          content: "Onsiter System - Quản lí dự án xây dựng toàn diện",
          link: "https://bimcad-vietnam.com/vi/construction-software/vinacad",
        },
        {
          content: "Onsiter Cloud CDE trên Cloud",
          link: "https://bimcad-vietnam.com/bim-viewer/bim-web-viewer",
        },
      ],
    },
    bimCad: {
      title: "Phần mềm CAD/BIM",
      items: [
        {
          content: "VinaCAD - Phần mềm CAD số 1 Việt Nam",
          link: "https://vina-cad.com/",
        },
        {
          content: "VinaBIM - Bảo trì và vận hành dự án",
          link: "https://vina-cad.com/",
        },
        {
          content: "VinaQ - Bóc tách khối lượng",
          link: "https://vina-cad.com/",
        },
        {
          content: "TakaCAD - Triển khai bản vẽ kết cấu 2D",
          link: "https://vina-cad.com/request-code?product=takacad",
        },
        {
          content: "BrightBIM - Plugin bố trí thép cho Revit",
          link: "https://vina-cad.com/request-code?product=brightbim",
        },
      ],
    },
  },

  about_us: {
    banner: {
      title: "Nâng tầm giá trị con người Việt!",
      sub: 'Với phương châm "Xây dựng công ty vững mạnh lấy con người làm trung tâm", mọi chính sách, quyết định của công ty đều hướng đến xây dựng một môi trường làm việc thoải mái nhất, tạo mọi điều kiện để nhân viên phát huy hết tiềm năng, thế mạnh bản thân và định hướng sự nghiệp.',
    },

    message: {
      title: "Hãy làm việc thật vui vẻ, có vui vẻ thì mới làm việc tốt",
      sub: {
        first: "Tôi có hai điều muốn nhắn nhủ đến với tất cả mọi người.",
        second:
          "Trước hết, “Hãy làm việc thật vui vẻ, có vui vẻ thì mới làm việc tốt.” Một cách đương nhiên, nhân viên làm việc tốt thì công ty sẽ phát triển. Công ty phát triển sẽ lại trả bonus dự án, tăng lương, thưởng và các chế độ đãi ngộ cho mọi người. Và mọi người lại vui vẻ. Như thế mới tạo ra một sự phát triển bền vững, trước hết là cho công ty, sau đó góp phần hướng đến sự cống hiến cho cộng đồng và xã hội.",
        third:
          "Thứ hai, trong bối cảnh thế giới đang thay đổi rất nhanh hiện nay, tôi nghĩ không chỉ các cấp quản lí mà các nhân viên đều cảm thấy bối rối, đôi khi mất phương hướng và không biết lựa chọn hướng đi nào là hợp lí nhất. Trong hoàn cảnh ấy, hãy luôn giữ vững tinh thần 3T: Thay đổi, Thử & sai, và Tiên phong đi đầu.",
        four: "",
      },
      acthor: "CEO, CO-FOUNDER, TIẾN SĨ LÊ TIẾN TRIỂN",
      btnView: "Xem thêm",
    },

    slogan: [
      "Khi giải quyết vấn đề cần chọn cách làm Smart nhất và hiệu quả nhất.",
      "Thay vì chần chừ, hãy quyết định nhanh, để nếu có sai lầm thì phát hiện nhanh và sửa chữa nhanh.",
      "Kết hợp “Smart” và “Speed” sẽ cho ra “Solutions” hiệu quả nhất, mang lại giá trị tối ưu cho khách hàng.",
    ],

    vision: {
      title: "Tầm nhìn và Sứ mệnh",
      cards: [
        {
          title: "Mission",
          sub: "Nâng tầm giá trị con người Việt!",
          content:
            "Với phương châm lấy con người làm trung tâm, TGL Solutions đề cao việc nuôi dưỡng, đào tạo và phát triển con người về tư duy giải quyết vấn đề, phong cách làm việc chuyên nghiệp, tinh thần không ngừng thử thách và cải thiện bản thân. Qua đó, TGL hướng tới việc nâng tầm giá trị con người Việt Nam, giúp chúng ta đảm nhận được các dự án ở cấp độ cao hơn trong chuỗi cung ứng toàn cầu, góp phần vào sự phát triển đất nước.",
        },
        {
          title: "Vision",
          sub: "Luôn lấy con người làm trung tâm trong mọi quyết định và hành động. ",
          content:
            "Con người chính là tài sản lớn nhất của công ty. Một cách đương nhiên, con người phát triển sẽ thúc đẩy công ty phát triển. Công ty phát triển sẽ tăng phúc lợi cho các thành viên, cải thiện môi trường làm việc để tạo mọi điều kiện và động lực giúp con người phát triển. Những điều này tạo nên một “vòng tuần hoàn bền vững” thúc đẩy công ty vươn đến những tầm cao mới, hiện thức hóa Vision của TGL là “Nâng tầm giá trị con người Việt”!",
        },
      ],
    },

    value: {
      title: "Giá trị cốt lõi",
      sub: "Dựa vào giá trị cốt lõi Nhân Hòa, công ty luôn cố gắng xây dựng môi trường làm việc thoải mái nhất, tạo mọi điều kiện để nhân viên phát huy tiềm năng và thế mạnh của bản thân.",
      cards: {
        people: {
          title: "Nhân",
          slogan: "S (Security) >> Q (Quality) > D (Delivery) > C (Cost)",
          subject:
            "Là con người. Tôn chỉ của TGL chính là xây dựng công ty vững mạnh lấy con người làm trung tâm.",
          content:
            "S (Security): Luôn ưu tiên bảo mật an toàn thông tin cho dự án và khách hàng. Bảo mật & Chất lượng là ưu tiên hàng đầu của TGL Solutions.\nTiếp sau S là Q: Quality, là chất lượng sản phẩm. Luôn phải ưu tiên chất lượng sản phẩm hơn thời hạn giao hàng (D: Delivery) và chi phí (C: Cost).\nTức là dù cho đến thời hạn nộp hàng nhưng chất lượng chưa tốt, thì cũng ưu tiên nâng cao chất lượng sản phẩm.",
        },
        piece: {
          title: "Hòa",
          subject:
            "Là sự hòa hợp đồng lòng, là tinh thần tích cực, là môi trường lành mạnh mà TGL Solutions xây dựng và khuyến khích tất cả thành viên cần phải có.",
          content:
            "TGL luôn khuyến khích mọi ý kiến trái chiều, khuyến khích tranh luận để tìm ra phương án tối ưu. Khi đã quyết định được phương án thực hiện thì mọi members đều phải đồng lòng hướng đến mục tiêu chung.",
        },
      },
    },

    rule: {
      title: "Nguyên tắc làm việc",
      sub: "Bạn có biết TGL nghĩa là gì không?",
      cards: [
        {
          title: "Thông báo",
          sub: "Khi một sự việc thay đổi, cần phải thông báo sự việc cho tất cả các bên liên quan đều biết.",
        },
        {
          title: "Giải thích",
          sub: "Khi trình bày vấn đề đó cần phải giải thích rõ mục đích của cách làm, từ mục đích hãy suy ngược ra phương pháp để đạt được mục đích đặt ra.",
        },
        {
          title: "Luận bàn",
          sub: "Hãy đưa vấn đề ra để team work với tất cả mọi người. Kết nối nhiều ý tưởng sẽ giúp ta giải quyết vấn đề hoàn thiện hơn và tối ưu hơn.",
        },
      ],
    },

    ourFirstPenguin: {
      title: "Gặp gỡ ban lãnh đạo công ty",
      sub: "Cùng nhau phát triển.",
      cards: [
        {
          name: "Phạm Phú Cường",
          avatar: "/images/aboutus/Cuong.png",
          position: "CTO TGL Solutions",
          content:
            'Quan điểm của tôi là làm việc là để tạo ra giá trị cho xã hội. Khi bạn sử dụng một tiện ích, đó là giá trị do người khác mang đến. Làm việc không phải là "bán mình cho tư bản", mà là đóng góp tri thức và kỹ năng để tạo ra giá trị, và nhận thù lao tương xứng. Vì thế, hãy không ngừng rèn luyện, nâng cao năng lực để giá trị bạn tạo ra ngày càng lớn, thù lao càng cao, và còn có được sự thỏa mãn, ghi nhận từ những người xung quanh.',
        },
        {
          name: "Bùi Sỹ Hiệp",
          avatar: "/images/aboutus/Hiep.png",
          position: "CAD Solutions Department Manager",
          content:
            "Là trưởng phòng đội ngũ phát triển phần mềm BIM/CAD, tôi cùng đồng nghiệp không ngừng sáng tạo để mang đến những giải pháp công nghệ tiên tiến, giúp tối ưu hóa quy trình xây dựng và nâng cao hiệu quả làm việc cho các kiến trúc sư, kỹ sư và nhà thầu. Chúng tôi tin rằng công nghệ BIM/CAD sẽ cách mạng hóa ngành xây dựng, và đội ngũ của tôi luôn nỗ lực không ngừng để hiện thực hóa điều đó.",
        },
        {
          name: "Lê Tiến Triển",
          avatar: "/images/aboutus/Trien.png",
          position: "CEO/ CO-FOUNDER",
          content:
            "Hãy ưu tiên hành động hơn là chờ đợi và quan sát, nói lên ý kiến của mình hơn là im lặng. Và ở TGL, không có sự lựa chọn nào là sai cả, chỉ cần bạn xác định và chia sẻ cho mọi người biết mục đích của mình trước khi thực hiện bất cứ việc gì.",
        },
        {
          name: "Ngô Xuân Huy",
          avatar: "/images/aboutus/Huy.png",
          position: "Head of SPEED Department",
          content:
            '"Mọi việc đều phải làm theo đúng quy trình" là chìa khóa mà tôi áp dụng để mở các cánh cửa công nghệ, vượt qua thử thách. Không chỉ là làm theo quy tắc mà tôi luôn muốn tạo một môi trường làm việc thoải mái cho tất cả mọi thành viên bởi tôi tin chỉ khi có sức khỏe và tinh thần thoải mái thì con người mới phát huy được tối đa năng lực và làm việc tốt được.',
        },
        {
          name: "Lê Bê Ly",
          avatar: "/images/aboutus/Ly.png",
          position: "HR Manager",
          content:
            "Với tư cách là người phụ trách mảng hành chính nhân sự tại công ty, tôi luôn cố gắng đảm bảo quyền lợi của các thành viên như quyền lợi của chính mình. Bằng tất cả nỗ lực và tâm huyết, tôi hy vọng TGL Solutions sẽ là nơi mà các thành viên có thể cùng nhau học tập, làm việc hết mình vì đam mê, cùng nhau vui vẻ, chia sẻ và kết quả chúng ta sẽ cùng nhau trưởng thành.",
        },
      ],
    },

    morale: {
      title: "Tinh thần",
      cards: [
        {
          title: "Thay đổi",
          sub: "Thay đổi liên tục để làm mới hiện tại. Để thay đổi được thì phải luôn tôn trọng các ý kiến khác biệt, nhân viên luôn được khuyến khích tích cực đưa ra ý kiến.",
        },
        {
          title: "Thử và sai",
          sub: "Thế giới thay đổi rất nhanh, thay vì chần chừ thì hãy ưu tiên hành động hơn là chờ đợi và quan sát, nói lên ý kiến của mình hơn là im lặng.\nKhi gặp thất bại ở đâu thì cùng ngồi lại, phân tích và tìm hiểu nguyên nhân ở chính chỗ đó, sau đó tìm cách khắc phục và cải tiến.",
        },
        {
          title: "Tiên phong đi đầu",
          sub: "Be the first penguin!\nHãy là con chim đầu tiên dám đột phá, dám thay đổi, điều đó sẽ kéo cả tập thể thay đổi theo.",
        },
      ],
    },

    welcome: {
      title: "ĐỒNG HÀNH SỨ MỆNH NÂNG TẦM GIÁ TRỊ CON NGƯỜI VIỆT!",
      sub: "TGL Solutions luôn tìm kiếm những con người đam mê thử thách để tạo nên những giá trị.",
      btn: "Cùng nhau phát triển",
    },
  },

  people: {
    banner: {
      title:
        "Câu chuyện về những chú chim cánh cụt “tiên phong” của TGL Soluitons",
      sub: "Có hơn 19 loài “Penguin” trên thế giới nhưng loài “Penguin” có nhiều nhất tại TGL là “First Penguin” – loài vật vừa đáng yêu vừa kiên cường chịu đựng điều kiện khắc nghiệt, và cũng là chiến binh dám tiên phong, đột phá.",
    },

    firstPenguin: {
      btn: {
        seeMore: "Đọc thêm",
        seeLess: "Ẩn bớt",
      },
      data: {
        title: "The First Penguin",
        welContent:
          "Có hơn 19 loài “Penguin” trên thế giới nhưng loài “Penguin” có nhiều nhất tại TGL là “First Penguin” – loài vật vừa đáng yêu vừa kiên cường chịu đựng điều kiện khắc nghiệt, và cũng là chiến binh dám tiên phong, đột phá.",
        sub: "\nTrong mùa săn mồi, tất cả chim cánh cụt tập trung ở đỉnh của tảng băng trôi và nhìn ra biển để xem có cá hay mối nguy hiểm nào gần đó không. Tuy nhiên không phải ai cũng sẽ vượt qua nỗi sợ hãi để nhảy xuống đầu tiên. Cũng giống như con người, động vật cũng hiểu chúng sẽ phải đối mặt với rủi ro, nguy hiểm không lường trước. Khoảng cách từ mõm đá xuống biển khá xa. Hơn nữa, chúng có thể tìm thấy cá, có thể không tìm thấy gì, hoặc thậm chí tệ hơn, có thể bị tấn công bởi một con sư tử biển.\n\n Ai đó phải là con chim cánh cụt nhảy xuống đầu tiên?\n\n Thế là “First Penguin” đã lựa chọn tiên phong. Sau một chút quan sát thấy không có chuyện gì to tát, thì con chim thứ 2, thứ 3 rồi thứ 4, 5 cũng nhảy xuống biển.\n\n Đừng nhầm lẫn – “First Penguin” không hề ngu ngốc hay liều lĩnh. Nhảy vào đại dương luôn là một rủi ro nhưng lại là một rủi ro có tính toán. Dù có thể bị tấn công bởi những kẻ săn mồi nhưng “First Penguin” vẫn có thể tìm ra được các cách để sinh tồn. Và tất nhiên là “First Penguin – First Eat”. Không chỉ thế, khi một con chim đầu đàn dám đột phá sẽ kéo theo một tập thể dám thay đổi theo.",
        question:
          "Somebody has got to be the First Penguin.\nWhy not me?\nWhy not you?",
      },
      poem: [
        {
          title:
            "“Nếu bạn không phải là con chim đầu tiên, thì hãy là con chim thứ hai. Nếu lần này bạn không phải là con chim đầu tiên, và thứ hai, thì hãy là con chim đầu tiên của những lần tiếp sau.”",
          sub1: "Tại TGL, các thành viên được gọi bằng “Penguin” nhằm khích lệ mọi người hãy “Be the First Penguin” dám thay đổi, vượt ra khỏi vòng an toàn của mình, chấp nhận rủi ro và trở thành những người tiên phong. Thay vì chần chừ suy nghĩ làm thế nào để trở thành những “First Penguins”, hãy bắt đầu những bước đầu tiên:",
          sub2: "Thay đổi, thay đổi cách thức làm việc, thay đổi những thứ mà mình đã quá quen thuộc trong cuộc sống thường ngày. Một khi bạn quyết định Thay đổi, điều đó đồng nghĩa với việc bạn chấp nhận những rủi ro và mạo hiểm. Chấp nhận rủi ro chính là sẵn sàng chấp nhận phải Thử và Sai nhiều lần, thậm chí là thất bại. Nhưng điều đó cũng sẽ dẫn đến sự khác biệt và có thể đưa mọi thứ đi theo một hướng mới, mạnh mẽ hơn, là động lực để giúp bạn trở thành những người Tiên phong đi đầu trong lĩnh vực của mình.",
          show: "Đọc thêm",
          hide: "Ẩn bớt",
        },
        {
          title:
            "“Đặt mục tiêu với mọi niềm tin.\nLập kế hoạch tính đến mọi rủi ro.\nThưởng thức những bất ngờ trên đường đi tới”",
          sub1: "TGL cũng đã và đang khuyến khích, tôn vinh những First Penguin dám thay đổi và đột phá dưới hình thức giải thưởng “Be the first Penguin (một trong 3 giải thưởng TGL Awards lớn nhất năm của công ty, trị giá giải thưởng là một chuyến du lịch Nhật Bản trong vòng 1 tuần).\n\nĐây là một trong những cách để Ban lãnh đạo công ty khuyến khích các Penguins thay đổi, khám phá những điều khác biệt, hành động táo bạo và luôn cố gắng trở thành phiên bản tốt nhất có thể của chính mình.",
          sub2: "Và mặc dù đúng là những nỗ lực tiên phong của Penguin không phải lúc nào cũng thành công, nhưng điều đó không sao cả. Tại sao?\n\nBởi vì không quan trọng bạn là ai và ở vị trí nào, miễn là trong quá trình vượt ra khỏi vùng an toàn của mình, bạn thực sự đang tiến bộ so với những bản thân mình, bạn đang nâng tầm cao hơn vì lợi ích cả tập thể– bạn đang thử nghiệm các khả năng và học cách phạm sai lầm, đồng thời học hỏi từ những sai lầm đó.\n\nVà Thay đổi, Thử và Sai, Tiên phong đi đầu chính là tinh thần 3T mà công ty muốn truyền đạt đến tất cả các thành viên thông qua hình tượng Penguins – là linh vật của TGL.",
          show: "Đọc thêm",
          hide: "Ẩn bớt",
        },
      ],
    },

    studyDevelop: {
      title: "Học tập & Phát triển",
      sub: "Với phương châm “Xây dựng Công ty vững mạnh, lấy con người làm trung tâm”, việc đầu tư phát triển kỹ năng, tổ chức đào tạo,... được chúng tôi ưu tiên hàng đầu.",
      data: [
        {
          step: "Lớp học tiếng Nhật",
          sub: "Tại TGL, phần lớn khách hàng đến từ thị trường Nhật Bản nên việc thông hiểu tiếng Nhật sẽ là một trong những chìa khóa giúp cho các dự án được vận hành suôn sẻ hơn.\n\nCác khóa học tiếng Nhật ở TGL được giảng dạy bởi giao viên bản xứ và các trợ giảng có trình độ N1, N2 JLPT tốt nghiệp khoa Nhật ngữ.",
        },
        {
          step: "Lớp học tiếng Anh",
          sub: "Hiện tại lớp học tiếng Anh tại TGL được tổ chức ngay tại văn phòng công ty sau giờ làm việc để đảm bảo sự thuận tiện nhất cho tất cả nhân viên. Lớp học được chia thành 2 cấp độ khác nhau tương ứng với mong muốn và kĩ năng của các thành viên khác nhau. Lớp nâng cao sẽ được học trực tiếp với Thầy bản xứ.",
        },
        {
          step: "Chương trình Go to Japan",
          sub: "Không chỉ dừng lại ở việc khuyến khích nhân viên học tiếng Nhật, TGL Solutions còn giúp thực hiện hóa giấc mơ Nhật Bản của những Penguins muốn thay đổi, thử thách bản thân ở một môi trường mới, và có định hướng trở thành những kĩ sư cầu nối/ quản lí dự án trong tương lai.\n\nCác thành viên sẽ được học tiếng Nhật trực tiếp tại công ty, sau đó sẽ được công ty tài trợ mọi chi phí học tập và thủ tục để sang Nhật học tập nâng cao tiếng Nhật một năm và làm việc. Sau khi tốt nghiệp và đạt được trình độ JLPT N2/N1, các học viên sẽ được tham gia vào trực tiếp dự án với vai trò là các kĩ sư cầu nối/quản lí dự án làm việc trực tiếp với khách hàng Nhật Bản.\n\nTGL Go To Japan đã tổ chức thành công 3 mùa đầu tiên và vẫn tiếp tục duy trì qua các năm. Việc của bạn là chỉ cần có đam mê và định hướng thử thách bản thân, tất cả đã có TGL hỗ trợ đào tạo và lo các khoản chi phí nhé.",
        },
        {
          step: "Khóa đào tạo chuyên sâu, Seminar, talkshow chia sẻ kinh nghiệm",
          sub: "TGL Solutions khuyến khích các Department, Unit tổ chức các Seminar, Talkshow để chia sẻ các kiến thức về công nghệ và các cách làm việc hiệu quả. Ngoài ra, TGL Solutions còn mời các chuyên gia đến chia sẻ những kiến thức, kinh nghiệm trong công việc và cuộc sống.",
        },
        {
          step: "Khóa đào tạo văn hóa nội bộ",
          sub: "“Nhân Hòa” là hai giá trị cốt lõi của TGL nhằm xây dựng một môi trường làm việc lành mạnh, thoải mái nhất cho các thanh viên. Tại ngày đầu tiên gia nhập TGL, các thành viên sẽ được giới thiệu chi tiết về văn hóa làm việc của TGL thông qua Sổ tay văn hóa được biên soạn và cập nhật hằng kì, ngoài ra còn được trải nghiệm các bài Test về văn hóa, tinh thần công ty.\n\nThông qua Sổ tay văn hóa TGL và các bài test, bạn sẽ hiểu được tinh thần 3T: Thay đổi, Thử & Sai và Tiên phong đi đầu mà công ty xây dựng. Không chỉ thế, phong cách làm việc TGL: Thông báo – Giải thích – Luận bàn cũng là một yếu tố làm nên bản sắc đặc trưng trong phong cách làm việc của toàn thể thành viên TGL Solutions.",
        },
      ],
    },

    workplace: {
      title: "Môi trường làm việc “Be supportive”",
      sub: "Đặc trưng của TGL chính là sức trẻ. Ở đây bạn có thể dễ dàng cảm nhận được không khí tươi vui, môi trường luôn hòa đồng, giúp đỡ lẫn nhau để “Together We Grow”.",
      cards: {
        subOkrs:
          "Mỗi phòng ban/unit luôn đặt ra sự gắn kết, chia sẻ trong nội bộ làm mục tiêu quan trọng trong bộ OKRs của mình.",

        subParty:
          "Các câu lạc bộ thể thao (bóng đá, cầu lông,...), hoạt động workshop, happy hour hàng tháng, du lịch toàn công ty hằng năm.",

        subHeart:
          "Chăm sóc sức khoẻ thể chất và tinh thần, khám sức khỏe định kì.",

        subEar:
          "Minh bạch-lắng nghe-chia sẻ giữa nhân viên và Ban điều hành luôn là tiêu chí đầu tiên công ty hướng đến.",

        subGroup:
          "Thúc đẩy sự chủ động cùng tập thể đưa ra những quyết định phát triển tổ chức.",
      },
    },

    develop: {
      title: "Phát triển bền vững",
      sub: "Phát triển bền vững chính là đích đến của TGL Solutions. “Vòng tuần hoàn bền vững” được thể hiện ngay ở logo công ty và hiện thực hóa trong các chính sách, hoạt động thường ngày của công ty. Để phát triển bền vững TGL luôn lấy con người làm trung tâm trong mọi quyết định và hành động, tập trung đào tạo và phát triển con người, liên tục thay đổi và cập nhật các xu hướng công nghệ đáp ứng yêu cầu theo tinh thần 3T.",
      conslusion:
        "“Một cách đương nhiên, nhân viên làm việc tốt thì công ty sẽ phát triển. Công ty phát triển sẽ lại trả bonus dự án, tăng lương, thưởng và các chế độ đãi ngộ cho mọi người. Và mọi người lại vui vẻ. Như thế sẽ tạo ra một sự phát triển bền vững, trước hết là cho công ty, sau đó góp phần hướng đến sự cống hiến cho cộng đồng và xã hội.”",
    },

    welcome: {
      title: "ĐỒNG HÀNH SỨ MỆNH NÂNG TẦM GIÁ TRỊ CON NGƯỜI VIỆT!",
      sub: "TGL Solutions luôn tìm kiếm những con người đam mê thử thách để tạo nên những giá trị.",
      btn: "Cùng nhau phát triển",
    },
  },

  project_detail: {
    title_project_detail: "Chi tiết dự án",
    back: "Quay lại",
    not_found: "Không tìm thấy",
    technical: "Công nghệ:",
    shareLabel: "Chia sẻ từ Project Leader",
    listProjectDetails: [
      {
        id: 1,
        title: "Hệ thống AI hỗ trợ chẩn đoán bệnh xơ phổi vô căn",
        category: "AI",
        pic: "Nguyễn Duy Đạt",
        avatar: "/images/members/Dat.jpg",
        tech: "Công nghệ: Python, Docker, Semantic Segmentation, Deep Learning, Image Processing",
        message:
          "Dự án này làm tăng cơ hội phát hiện bệnh ở giai đoạn sớm, qua đó làm tăng cơ hội chữa trị cho bệnh nhân.",
        image: ["/images/ai/1_1.webp", "/images/ai/1_2.png"],
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Bệnh xơ phổi vô căn (IPF) là gì?\nTình trạng phổi mất dần chức năng hô hấp và không rỏ nguyên nhân gây ra bệnh.\nXơ phổi vô căn là bệnh phổi thường gây tử vong và khó chẩn đoán, cho đến nay vẫn chưa có số liệu chính xác về số lượng người mắc bệnh xơ phổi. Tính đến năm 2020, có khoảng 180.000 trường hợp xơ phổi vô căn ở Hoa Kỳ và 40.000 người chết vì căn bệnh này mỗi năm. Bên cạnh đó, vào năm 2020 tại Hoa Kỳ, số người chết vì tai nạn giao thông là 38.680 người, đây là mức cao nhất trong 13 năm. Nhưng con số này vẫn thấp hơn số người chết vì căn bệnh này.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Thông thường, thời gian sống sót trung bình của một bệnh nhân khoảng 3 năm kể từ khi chẩn đoán có bệnh. Tuy nhiên, công nghệ AI tiên tiến có thể chẩn đoán sớm và chính xác bệnh phổi vô căn IPF. Nhờ đó, bệnh nhân mắc bệnh sẽ có thêm nhiều cơ hội chữa trị hoặc kéo dài thời gian sống.\nThuật toán dự đoán khả năng sống sót, từ đó đưa ra phác đồ điều trị cho bệnh nhân.",
          },
        ],
      },
      {
        id: 2,
        title: "Ứng dụng AI thay đổi nội/ngoại thất ngôi nhà",
        category: "AI",
        pic: "Nguyễn Duy Đạt",
        avatar: "/images/members/Dat.jpg",
        tech: "Công nghệ: Python, Deep Learning, Image Segmentation, Digital Image Processing",
        message:
          "Nhu cầu về thiết kế ngoại thất theo ý muốn đang ngày càng tăng, cho nên chúng tôi đã phát triển giải pháp tự động để nhanh chóng đáp ứng nhu cầu trang trí lại ngôi nhà của quý khách.",
        image: ["/images/ai/2_1.webp", "/images/ai/2_2.png"],
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Gia chủ muốn thay đổi ngoại thất ngôi nhà thì thường thực hiện theo các bước sau:\n1. Chụp ảnh ngoại thất ngôi nhà và phiếu yêu cầu.\n2. Kiến trúc sư sử dụng Photoshop để chỉnh sửa ảnh theo ý muốn của gia chủ.\n3. Gia chủ xem ảnh đã chỉnh sửa và đưa ra quyết định, thường mất ít nhất 1 ngày để chỉnh sửa màu sắc ngoại thất. Chưa kể thời gian mà các bên gặp nhau để thảo luận và trao đổi. \n=> Chúng tôi sử dụng công nghệ AI để quý khách chọn ảnh và chủ động tùy chỉnh màu ngoại thất theo ý muốn trên thiết bị di động.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Chức năng nhận diện và thay đổi màu sắc của ngoại thất theo ý muốn và chưa đến 30 giây là đã có ngay hình ảnh ngôi nhà mà quý khách mong muốn.",
          },
        ],
      },
      {
        id: 3,
        title: "Hệ thống AI chẩn đoán bệnh tim bằng âm thanh",
        category: "AI",
        pic: "Nguyễn Duy Đạt",
        avatar: "/images/members/Dat.jpg",
        tech: "Công nghệ: Audio Processing, Auto cut-off frequency, Deep Learning, Audio Classification, Spectrogram, Python",
        message:
          "Dự án giúp người dùng chủ động trong việc kiểm tra sức khỏe của bản thân thông qua nhịp tim mọi lúc, mọi nơi và đưa ra những tư vấn phù hợp với tình trạng sức khỏe hiện tại.",
        image: ["/images/ai/3_1.webp", "/images/ai/3_2.png"],
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Người dùng có thể tự theo dõi sức khỏe bằng cách thu lại nhịp tim. Hệ thống AI sẽ chẩn đoán các bệnh về tim trên tệp âm thanh mà khách gửi về máy chủ. Từ đó, khách hàng có thể chủ động theo dõi tình trạng sức khỏe của mình và nhận được hỗ trợ y tế sớm nhất.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            // content:
            //   'Tính năng:\n- Chẩn đoán nhịp tim của khách hàng là bình thường hay bất thường.\n- Chẩn đoán cụ thể vấn đề về tim của khách hàng.\n\nHiệu quả ở những điểm sau:\n- Chủ động với người dùng, họ có thể tự kiểm tra tình trạng sức khỏe của mình.\n- Hệ thống tự cảnh báo, liên hệ với cơ sở y tế gần nhất khi phát hiện nhịp tim bất thường và tự gọi cấp cứu dựa trên định vị.\n- Lưu trữ dữ liệu, bác sĩ có thể nhanh chóng truy cập lịch sử nhịp tim của bệnh nhân và đưa ra quyết định phù hợp.',
            content:
              "Chẩn đoán nhịp tim của khách hàng là bình thường hay bất thường.\nChẩn đoán cụ thể vấn đề về tim của khách hàng.\nChủ động với người dùng, họ có thể tự kiểm tra tình trạng sức khỏe của mình.\nHệ thống tự cảnh báo, liên hệ với cơ sở y tế gần nhất khi phát hiện nhịp tim bất thường và tự gọi cấp cứu dựa trên định vị.\nLưu trữ dữ liệu, bác sĩ có thể nhanh chóng truy cập lịch sử nhịp tim của bệnh nhân và đưa ra quyết định phù hợp.",
          },
        ],
      },
      {
        id: 4,
        title: "Dự án Metaverse cho nhà máy",
        category: "Metaverse",
        pic: "Nguyễn Duy Đạt",
        avatar: "/images/members/Dat.jpg",
        tech: "Công nghệ: Omniverse nvidia, Actorcore",
        image: ["/images/ai/4_1.webp", "/images/ai/4_2.jpg"],
        message:
          "Thay vì xây dựng nhà máy thật rất tốn kém và nhiều rủi ro tiềm ẩn. Quý khách có thể mô phỏng một nhà máy trong thế giới ảo với đầy đủ tính năng để kiểm tra khả năng hoạt động với chi phí thấp hơn rất nhiều.\n Chưa kể, quý khách có thể tham quan bệnh viện, trường học, nhà máy trong không gian ảo trước khi tham quan thực tế.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Hiện nay, các nhà đầu tư và nhà sản xuất dành rất nhiều thời gian, công sức và tiền bạc cho quá trình lắp đặt/chế tạo. Khi lắp đặt nhà máy, nếu tính toán sai hoặc không kiểm soát được hết các tình huống phát sinh thì chi phí và thời gian dành cho vấn đề này sẽ tăng lên rất nhiều.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Mục tiêu của Digital Twin là mô phỏng thế giới thật với đầy đủ phương thức vận hành, dự đoán rủi ro của sản phẩm hoặc quy trình sản xuất, do đó giảm phần lớn thời gian dành cho cài đặt/sản xuất.\nTiết kiệm chi phí thông qua việc tham quan thế giới ảo",
          },
        ],
      },
      {
        id: 5,
        title: "Dự án CAD1",
        category: "BIM/CAD",
        pic: "Phan Trần Minh Trí",
        avatar: "/images/members/Tri.jpg",
        tech: "Công nghệ: C#, WPF",
        image: ["/images/cad/5_1.webp", "/images/cad/5_2.jpg"],
        message:
          "Để đạt được thành công của dự án thì yếu tố đầu tiên là sự thành công của đoàn đội, của tất cả member trong team. Để làm được điều đó, chúng ta cần kế hoạch cụ thể, rõ ràng và tinh thần cố gắng hết sức vì công việc và mục tiêu đã đặt ra.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Cùng với sự phát triển mạnh mẽ và vượt trội của Autocad, một số sản phẩm phát triển tương tự đã ra đời như: Gcad, BricsCad, DraftSight với mong muốn cung cấp một loạt các chức năng tương tự Autocad nhưng với chi phí rẻ hơn. Khách hàng doanh nghiệp muốn xây dựng mô hình, phần mềm hỗ trợ kỹ thuật cho xây dựng với chi phí hợp lý để phá vỡ thị trường độc quyền CAD.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Phân tích, thiết kế và phát triển các câu lệnh-command trong bản vẽ CAD. Điều khiển được tối ưu hóa để mô phỏng và xây dựng các đối tượng như nhà cửa, vật liệu xây dựng... với độ chính xác rất cao. Giúp thi công, thiết kế nhanh hơn, nâng cao hiệu quả công việc cho ngành xây dựng.",
          },
        ],
      },
      {
        id: 6,
        title: "Dự án NewCAD",
        category: "BIM/CAD",
        pic: "Phan Trần Minh Trí",
        avatar: "/images/members/Tri.jpg",
        tech: "Công nghệ: .NET, AutoCAD API",
        image: ["/images/cad/6_1.webp", "/images/cad/6_2.jpg"],
        message:
          "Dự án này chưa có thông tin cụ thể và hiện tại đang làm prototype nên chưa có quá nhiều thông tin nhưng đây là dự án rất tiềm năng trong tương lai.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Nhu cầu thiết kế cơ sở hạ tầng ngày càng tăng, cần có một công cụ phát triển tự động theo khuôn mẫu cho sẵn và có thể linh hoạt sửa đổi để thích ứng với điều kiện luôn thay đổi hiện nay.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Giải pháp vẽ mô hình 3D cho nhà, cầu, cầu đường với độ phức tạp cao trong thời gian nhanh chóng.",
          },
        ],
      },
      {
        id: 7,
        title: "Dự án CAD4 (liên quan đến COM/ActiveX)",
        category: "BIM/CAD",
        pic: "Nguyễn Văn Ngọc Minh",
        avatar: "/images/members/Minh.jpg",
        tech: "Công nghệ: C++, COM/ActiveX",
        image: ["/images/cad/7_1.webp", "/images/cad/7_2.gif"],
        message:
          "Công nghệ COM/ActiveX sử dụng để tạo các module giúp cho việc tương tác giữa các phần mềm riêng biệt được thực hiện một cách thuận tiện và hiệu quả nhất.\nGiúp cho các phần mềm có thể tạo một các API để public cho phép các phần mềm khác tương tác với chính nó thông qua COM/ActiveX",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Đã có bộ thư viện được viết bằng C++ cần publish ra cho các module khác được viết bằng các ngôn ngữ khác như C#, VB.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Các mô-đun COM/ActiveX này có thể hoạt động độc lập với nhau và cũng có thể được vận hành bởi các mô-đun khác. Đây là những mô-đun được viết bằng nhiều ngôn ngữ khác nhau và rất đa dạng. Nhờ COM/ActiveX, các chương trình được phát triển riêng biệt có thể liên kết và tương tác theo nhiều cách khác nhau thông qua COM/ActiveX.",
          },
        ],
      },
      {
        id: 8,
        title: "Dự án Piping",
        category: "BIM/CAD",
        pic: "Nguyễn Văn Ngọc Minh",
        avatar: "/images/members/Minh.jpg",
        tech: "Công nghệ: ObjectArx, C#",
        image: ["/images/cad/8_1.webp", "/images/cad/8_2.jpg"],
        message:
          "Công ty đã nắm rõ công nghệ về ObjectArx, có thể thực hiện hóa những ý tưởng, giải pháp hỗ trơ các công việc trên AutoCAD được thực hiện một cách nhanh chóng, hiệu quả và chính xác hơn, từ đó giúp nâng cao hiệu quả công việc và đem lại nhiều lợi ích cho khách hàng.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Việc thiết kế hệ thống đường ống cho công trình được thực hiện trên bản vẽ CAD hiện nay vẫn còn khá khó khăn và phức tạp sẽ dẫn đến sai sót trong quá trình thực hiện.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Cung cấp thêm các lệnh AutoCAD cho phép người dùng thực hiện việc tạo hệ thống đường ống một cách đơn giản, nhanh chóng và chính xác hơn.\nLink video demo: https://www.youtube.com/watch?v=Um098tO_u7c&t=1s",
          },
        ],
      },
      {
        id: 9,
        title: "Dự án Revit",
        category: "BIM/CAD",
        pic: "Nguyễn Văn Ngọc Minh",
        avatar: "/images/members/Minh.jpg",
        tech: "Công nghệ: RevitAPI, C#",
        image: ["/images/cad/9_1.webp", "/images/cad/9_2.jpg"],
        message:
          "BIM sẽ là tương lai của ngành xây dựng. Và Revit là một phần mềm hàng đầu trong lĩnh vực BIM. Việc làm chủ công nghệ Revit API cho phép công ty tương tác hoàn toàn với Revit để tạo các phần bổ sung giúp tối đa hóa hiệu quả của việc triển khai BIM trong tương lai.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Việc thiết lập các bố trí kim loại cho các phần tử kết cấu của công trình (trụ, dầm, sàn,…) được thực hiện thủ công mất nhiều thời gian, có nhiều nguy cơ sai sót và đồng thời việc hiệu chỉnh đồng loạt gặp nhiều khó khăn.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Tạo tính năng giúp người dùng bố trí thép cho các cấu kiện một cách nhanh chóng và chính xác. Người dùng chỉ cần nhập các thông số cần thiết, phần mềm sẽ tự động sắp xếp thép theo thông tin đã nhập và cài đặt trước.\nLink video demo: https://www.youtube.com/watch?v=TfuOUMMqvWo",
          },
        ],
      },
      {
        id: 10,
        title: "Dự án Web Mirai",
        category: "Web System",
        pic: "Nguyễn Quý Lễ",
        avatar: "/images/members/Le.jpg",
        tech: "Công nghệ: Reactjs, Nextjs, Graphql, TypeOrm, Oracale",
        image: ["/images/web/10_1.webp", "/images/web/10_2.jpg"],
        message:
          "Thông qua dự án, công ty muốn mang đến cho khách hàng những gì họ thực sự muốn bằng cách không ngừng lắng nghe, chỉnh sửa và thiết kế các tính năng nhằm đáp ứng tốt nhất các yêu cầu của khách hàng.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Khách hàng mong muốn thành lập một trang Thương mại điện tử để hỗ trợ việc bán hàng của công ty. Tích hợp các tính năng livestream, VR... để tối ưu hóa doanh số bán hàng.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Tính năng đặt hàng, mua bán sản phẩm, tích lũy đổi điểm.\nGóc VR Showroom, góc video giới thiệu sản phẩm của nhà sản xuất.\nChức năng phát trực tiếp: Người phát trực tiếp có thể tổ chức và lên lịch phát trực tiếp. Người dùng có thể xem các chương trình phát sóng trực tiếp từ người bán hàng và mua hàng trực tiếp.\nTính năng đấu giá sản phẩm: Người phát trực tiếp có thể tổ chức đấu giá sản phẩm. Người dùng có thể tham gia đấu giá sản phẩm, người đấu giá thành công có thể đặt hàng với giá thắng.\nTrang quản trị: Quản lý sản phẩm, quản lý đơn hàng, tích điểm, thu nhập...; Xuất excel theo đơn hàng, theo sản phẩm, theo người dùng...; Có thể tạo link quickLogin để đăng nhập các trang Top, Admin; Có hệ thống gửi mail thông báo tình trạng đơn hàng, tình hình diễn biến tích điểm cho người dùng và người chịu trách nhiệm về đơn hàng.",
          },
        ],
      },
      {
        id: 11,
        title: "Dự án Lesson Room",
        category: "Web System",
        pic: "Phan Đăng Lâm",
        avatar: "/images/members/Lam.jpg",
        tech: "Công nghệ: HTML, CSS, JS, WebRTC, Skyway",
        image: ["/images/web/11_1.webp", "/images/web/11_2.jpg"],
        message:
          "Dự án Lesson Room không có quá nhiều chức năng khó nhưng là dự án được thực hiện và phát triển thêm nhiều tính năng từ mã nguồn cũ còn nhiều lỗi của khách hàng nên gây rất nhiều khó khăn cho team tối ưu code, sửa lỗi cũ và phát triển thêm các tính năng đặc trưng. Mặc dù đó là một thử thách, nhưng nhóm đã cố gắng hết sức để hoàn thành dự án trong khả năng của mình, để có thể giúp khách hàng đạt được các tính năng mong muốn.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Hiện nay, việc dạy và học trực tuyến đang trở nên phổ biến, đặc biệt là đợt dịch covid vừa qua khiến hầu hết mọi người đã quen với việc học tập và làm việc trực tuyến. Vì vậy, để đáp ứng nhu cầu của nhiều người, công ty khách hàng đã đặt làm website lớp học, với mục đích dạy và học trực tuyến.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "1/ Video call 1-1 giữa teacher và student\n2/ Share textbook tài liệu học tập 1 cách real-time\n3/ Chức năng whiteboard giúp teacher và student tương tác với nhau\n4/ Chức năng chat và gửi file\n5/ Chức năng note\n6/ Chức năng share screen\n7/ Chức năng record screen\n8/ Chức năng background filter",
          },
        ],
      },
      {
        id: 12,
        title: "Dự án YHK",
        category: "Web System",
        pic: "Phan Ngọc Thịnh",
        avatar: "/images/members/Thinh.jpg",
        tech: "Công nghệ: React, Ant Design, C# .NET, RESTful API",
        image: ["/images/web/12_1.webp", "/images/web/12_2.jpg"],
        message:
          "TNF rất may mắn được phụ trách dự án Core System mang tên YHK. Vì ai mà không muốn làm ra một sản phẩm ý nghĩa và mang lại lợi ích to lớn cho khách hàng đúng không? Trong quá trình thực hiện dự án, nhóm chúng tôi cũng đã học hỏi và tích lũy được nhiều kinh nghiệm, đây là một trong những tiền đề để có thể thực hiện những dự án quy mô hơn sau này.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "YHK là hệ thống quản lý tập trung, hỗ trợ đắc lực cho các nghiệp vụ liên quan đến quản lý kho hàng. Khách hàng muốn sử dụng YHK để thay thế hầu hết các công việc thủ công và hoàn thành công việc nhanh hơn, dễ dàng hơn.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Tối ưu hóa công việc đặt hàng, nhập kho, xuất kho.\nTự động xuất phiếu giao hàng.\nTự động tạo và gửi báo cáo định kỳ",
          },
        ],
      },
      {
        id: 13,
        title: "Dự án Spotlight",
        category: "Web System",
        pic: "Phan Ngọc Thịnh",
        avatar: "/images/members/Thinh.jpg",
        tech: "Công nghệ: NextJS, Laravel, WebRTC",
        image: ["/images/web/13_1.webp", "/images/web/13_2.jpg"],
        message:
          "Đây là một trong những dự án thú vị và rất tiềm năng trong tương lai. Hệ thống video call hiện nay rất phổ biến với nhiều nền tảng và được ứng dụng vào hệ thống trong bối cảnh xã hội sử dụng Internet để trao đổi thông tin với nhau. Và trong tương lai, hệ thống sẽ mở rộng và phát triển hơn gấp bội phần để đáp ứng mọi yêu cầu của xã hội.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Spotlight là hệ thống video call giữa 2 người dùng với nhiều loại thiết bị. Là giải pháp tiết kiệm chi phí cho doanh nghiệp, mang đến các tính năng và quản lý cuộc gọi một cách dễ dàng và hiệu quả nhất.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Dùng cho cuộc gọi video giữa trình duyệt web, trình duyệt web và ứng dụng web, giữa các ứng dụng web với nhau.\nKhả năng trao đổi dữ liệu giữa hai hoặc nhiều thiết bị mà không cần sử dụng máy chủ trung gian.\nĐể tạo WebPhone được sử dụng cho hội nghị web, hỗ trợ khách hàng, hướng dẫn trực tuyến, trò chơi, v.v.\nKhông tính phí cho các dịch vụ liên lạc, v.v., từ phía khách hàng hoặc công ty.\nChức năng chia sẻ màn hình cho phép bạn hỗ trợ khách hàng của mình một cách dễ dàng và an toàn.\nHệ thống Spotlight do skyACE phát triển là một WebRTC điển hình (hệ thống điện thoại doanh nghiệp) có thể kết nối trực tiếp với người dùng trên trình duyệt web.",
          },
        ],
      },
      {
        id: 14,
        title: "Dự án Girls Bar",
        category: "Web System",
        pic: "Phan Ngọc Thịnh",
        avatar: "/images/members/Thinh.jpg",
        tech: "Công nghệ: DB: Aurora\nLaravel-ver9, PHP-ver8.1, Bootstrap CSS\nNGINX",
        image: ["/images/web/14_1.webp", "/images/web/14_2.jpg"],
        message:
          "Đây là một dự án rất thú vị mà nhóm của chúng tôi được đảm nhận, thú vị cả về nội dung và công nghệ được sử dụng.\nVề nội dung, chúng ta có dịp tìm hiểu thêm về đất nước Nhật Bản, về những dịch vụ độc đáo như Girl’s bar.\nVề mặt công nghệ, đây là lần đầu tiên chúng tôi sử dụng Lavarel để thực hiện một dự án thực tế. Tuy còn nhiều bỡ ngỡ nhưng đó cũng là động lực to lớn giúp chúng tôi ngày càng phát triển hơn, tiên phong cho những công nghệ mới với tên gọi TNF – To New Future.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "The Girl’s bar là nhà hàng do một nữ nhân viên pha chế phụ trách pha chế và tiếp khách. Du khách có thể ngồi tại quầy bar vừa trò chuyện vừa yêu cầu nữ tiếp viên pha rượu cho mình. Tại Nhật Bản, Girl’s Bar là loại hình dịch vụ khá phổ biến và mang đậm nét văn hóa. Quan sát và nhận thấy rằng sự kết nối việc làm giữa những người cần việc làm và các quán Bar hiện nay đang chưa được hiện đại và hiệu quả, chúng tôi đã giúp khách hàng phát triển một sản phẩm mang tên GirlsMeee - Hệ thống kết nối việc làm chuyên biệt cho loại hình dịch vụ Girl’s bar.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Đối với ứng viên: GirlsMeee cho phép ứng viên tìm công việc thuận tiện cho việc đi lại của họ. Ngoài ra, những người từng làm việc tại quán Bar cũng có thể để lại đánh giá để các ứng viên khác tham khảo.\nĐối với nhà tuyển dụng: Các quán Bar có thể đăng tuyển và tìm kiếm những ứng viên phù hợp nhất với các tiêu chí của mình.",
          },
        ],
      },
      {
        id: 15,
        title: "App Kensa Cloud",
        category: "Smartphone App",
        pic: "Phan Ngọc Thịnh",
        avatar: "/images/members/Thinh.jpg",
        tech: "Công nghệ: React, React Native, Ant Design, TailwindCSS, .NET",
        image: ["/images/app/15_1.webp", "/images/app/15_2.jpg"],
        message:
          "Đây là một trong những sản phẩm tiền thân cho tham vọng lấn sân sang lĩnh vực Product của công ty. Tuy chỉ là một sản phẩm có quy mô không quá lớn nhưng hội tụ đủ tinh thần 3T: Thay đổi - Thử và Sai - Tiên phong đi đầu. Chúng tôi sẽ cố gắng hết sức để đứa con tinh thần này có thể đạt được những thành tích tuyệt vời trong tương lai gần.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Thông thường, chúng ta phải mất rất nhiều thời gian và công sức mới có thể tiến hành khảo sát công trình nhà ở, chụp ảnh để tổng hợp kết quả báo cáo cấp trên.\n- Công tác nghiệm thu giữa Chủ đầu tư (chủ đầu tư bỏ tiền vào BĐS) và Đơn vị thi công còn thiếu minh bạch, cơ chế quản lý cồng kềnh, kém hiệu quả.\n- Việc triển khai thực địa thiếu so sánh trực quan nên khó đánh giá chất lượng công trình.\n- Hệ thống lưu trữ và đồng bộ dữ liệu sau khi nghiệm thu giữa các bên chưa nhất quán và chuyên nghiệp.\n- Khó theo dõi dữ liệu xây dựng và năng suất theo thời gian thực.\nTừ những vấn đề đó, chúng tôi đã lên ý tưởng và tạo ra hệ thống Kensa Cloud với mục đích giải quyết tối đa những vấn đề nêu trên. Đây là một ứng dụng kiểm tra cho phép bạn dễ dàng thực hiện các cuộc khảo sát trên điện thoại thông minh của mình và tự động tạo báo cáo.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Khảo sát nghiệm thu công trình nhanh chóng và dễ dàng chỉ bằng một chiếc điện thoại (trực tuyến hoặc ngoại tuyến), tối ưu hóa năng suất của nhân viên.\nThúc đẩy tính minh bạch trong nghiệm thu, trực tiếp nâng cao chất lượng sản phẩm.\nTạo môi trường làm việc tối ưu giữa nhân viên khảo sát và quản trị viên.\nTự động tạo báo cáo và gửi kết quả lên đám mây chỉ bằng một nút bấm, khả năng kế thừa và tùy chỉnh báo cáo cực kỳ mạnh mẽ.",
          },
        ],
      },
      {
        id: 16,
        title: "App BLE",
        category: "Smartphone App",
        pic: "Huỳnh Huy Tuấn",
        avatar: "/images/members/Tuan.jpg",
        tech: "Công nghệ: Flutter, Sqlite, BLE, IOT",
        image: ["/images/app/16_1.webp", "/images/app/16_2.jpg"],
        message:
          "Với kiến ​​thức và kinh nghiệm của mình, chúng tôi mong muốn cung cấp các giải pháp công nghệ để giảm thiểu sức người. Sự hài lòng của khách hàng là niềm hạnh phúc và là động lực để chúng tôi hoàn thiện và phát triển sản phẩm.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Trong ngành ẩm thực, việc đo và theo dõi nhiệt độ của thực phẩm và đồ uống là rất quan trọng. Tuy nhiên, bạn sẽ tốn rất nhiều công sức khi phải theo dõi liên tục để tránh những sự cố không mong muốn như nhiệt độ quá cao hoặc quá thấp, thiết bị đo bị lỗi hoặc thiếu pin, v.v.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              'Với ứng dụng Bluetooth kết hợp wireless và cảm biến Chino MF100 sẽ là giải pháp tuyệt vời cho việc theo dõi và thống kê nhiệt độ trong thời gian dài.\nỨng dụng bao gồm các chức năng chính sau: \n- Theo dõi và hiển thị nhiệt độ của nhiều cảm biến theo thời gian thực.\n- Quản lý kết nối với các cảm biến.\n- Thống kê chi tiết kết quả đo theo ngày, giờ đo.\n- Phát âm thanh, gửi email khi có "dị thường". \n- Cài đặt thông số linh hoạt các "dị thường" như nhiệt độ giới hạn, lỗi cảm biến, v.v.',
          },
        ],
      },
      {
        id: 17,
        title: "App EPCO",
        category: "Smartphone App",
        pic: "Phan Huỳnh Minh Duy",
        avatar: "/images/members/Duy.jpg",
        tech: "Công nghệ: React Native, .NET Core, MySQL, Firebase",
        image: ["/images/app/17_1.webp", "/images/app/17_2.png"],
        message:
          "Hầu hết các thành viên trong nhóm dự án mặc dù có ít kinh nghiệm (gần như bằng 0 tại thời điểm mới tham gia) nhưng họ đã cố gắng phát triển và hoàn thành tốt vai trò của mình trong dự án. Đội ngũ luôn cố gắng chủ động trong công việc và sẵn sàng học hỏi, tiếp thu những kiến ​​thức mới để áp dụng cho bản thân và các dự án của mình, mang lại giá trị cho khách hàng.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Đối tác (công ty bất động sản) cần một ứng dụng trên điện thoại thông minh để phục vụ mục đích liên lạc giữa khách hàng và nhân viên của họ, đồng thời dần dần sẽ phát triển thêm nhiều tính năng khác.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "- Trò chuyện giữa khách hàng và nhân viên (bao gồm gửi tài liệu, hình ảnh, video).\n- Tạo các yêu cầu sửa chữa / kiểm tra định kỳ cho ngôi nhà.\n- Hiển thị thông tin, hướng dẫn... và một số tính năng khác.\n- Thực hiện các tính năng về Security Reinforcement, đảm bảo tính bảo mật cao cho dự án.\nThiết kế nhanh app di động trên cả 2 nền tảng iOS và Android, giúp cho doanh nghiệp tiết kiệm được thời gian, công sức và chi phí để phát triển và bảo trì.",
          },
        ],
      },
      {
        id: 18,
        title: "Dự án Ikkanka",
        category: "PC Application",
        pic: "Nguon Mẫn",
        avatar: "/images/members/Man.jpg",
        tech: "Công nghệ: C# .NET (WPF MVVM), .NET CORE (Oracle)",
        image: ["/images/app/18_1.webp", "/images/app/ikkanka_1.jpg"],
        message:
          "Kỷ nguyên Công nghệ 4.0 nơi mọi ngành nghề đều ứng dụng công nghệ, buộc doanh nghiệp phải thay đổi để bắt kịp sự phát triển của nền kinh tế. Mọi thứ đều có thể cải tiến và nâng cấp (tăng nhân lực, nâng cấp thiết bị,...) chỉ là thời gian có hạn, nếu tiết kiệm được hết mức sẽ là một giải pháp tuyệt vời nhất.",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Trong thời đại công nghệ 4.0, mọi thứ đều phải số hóa, trong đó logistics là yếu tố vô cùng quan trọng giúp doanh nghiệp tiết kiệm nhân lực, thời gian, từ đó nâng cao năng suất/chất lượng công việc.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Cung cấp cho khách hàng các giải pháp logistics để tạo thuận lợi cho việc đặt hàng cũng như giao nhận hàng hóa một cách nhanh chóng và hiệu quả (tối ưu hóa khoảng cách, thời gian, nhân lực).",
          },
        ],
      },
      {
        id: 19,
        title: "Dự án Shuho",
        category: "PC Application",
        pic: "",
        avatar: "/images/members/penguin.png",
        image: ["/images/app/19_1.webp", "/images/app/suho_1.jpg"],
        tech: "Công nghệ: C# .NET (WPF MVVM), .NET CORE (Oracle)",
        message: "",
        content: [
          {
            title: "1. VẤN ĐỀ & BỐI CẢNH:",
            content:
              "Để tạo bảng báo cáo tuần khách hàng phải vào website phân tích dữ liệu, sau đó lập báo cáo và thực hiện các thao tác thủ công như phải lập file excel tổng hợp: bảng biểu, đồ thị,... tùy theo dữ liệu mà phải in báo cáo rồi giao cho cấp trên. Do đó, họ muốn tạo một ứng dụng PC để tối đa hóa báo cáo hàng tuần và có các tính năng tự động cập nhật như một ứng dụng web.",
          },
          {
            title: "2. TÍNH NĂNG & GIẢI PHÁP:",
            content:
              "Dự án giúp tối ưu hóa thời gian tạo báo cáo từ 3 giờ xuống còn 10 phút với các tính năng như:\n- Hỗ trợ tự động thu thập dữ liệu phù hợp nhất cho báo cáo hàng tuần.\n- Tạo file báo cáo rõ ràng, sinh động do có thể can thiệp sâu vào file excel, tổng hợp và xuất file báo cáo ra excel.",
          },
        ],
      },
    ],
  },

  recruiment: {
    banner: {
      title: "Bắt đầu cuộc hành trình của bạn với TGL Solutions nào!",
      sub: "Môi trường lí tưởng mà TGL Solutions đang hướng tới là nơi tất cả mọi người đều được thoải mái tạo ra những giá trị tuyệt vời, mang lại sự thay đổi tích cực cho sự phát triển bền vững của công ty và đóng góp cho xã hội phát triển.\n\nTại TGL Solutions, chúng tôi luôn tìm kiếm những con người dám thay đổi, dám thử thách để hiện thực hóa những lí tưởng lớn trên hành trình nâng tầm giá trị con người Việt. Nếu bạn tự tin có thể trở thành thế hệ “First Penguin” tiếp theo thì đừng ngại ngùng  gia nhập đội ngũ TGL để cùng nhau chúng ta sẽ chinh phục những tầm cao mới.",
    },

    jobOpenings: {
      title: "Các vị trí đang tuyển dụng",
      btnShowAll: "Xem lại tất cả các vị trí đang tuyển dụng",
      listjob: [
        {
          id: 1,
          level: "Từ 3 năm kinh nghiệm",
          position: "KĨ SƯ CẦU NỐI TIẾNG NHẬT",
          sub: "Cạnh tranh với thị trường",
          extra: "Linh hoạt",
          content: [
            {
              title: "MÔ TẢ CHUNG",
              description:
                "***Lưu ý: Vị trí này tuyển dụng ứng viên cho chi nhánh Việt Nam và ứng viên ở Nhật cho cty mẹ ở Nhật Bản.\n　• Làm việc với khách hàng Nhật Bản để nhận yêu cầu từ phía khách hàng, họp với khách hàng để báo cáo/ thảo luận về tiến độ dự án.\n　• Tư vấn và trao đổi với khách hàng để lựa chọn công nghệ + các giải pháp tối ưu cho dự án.\n　• Thực hiện 用件定義、基本設計 cho các dự án với nhiều domain khác nhau (sẽ có các Tech Lead cho từng domain hỗ trợ).\n　• 	Theo dõi và quản lí tiến độ cho các dự án, thực hiện trao đổi Q&A trực tiếp với khách hàng và truyền đạt thông tin với team phát triển dự án\n　•	Dành cho ứng viên làm việc tại Nhật Bản: Hỗ trợ quản lí/ hướng dẫn các kỹ sư cầu nối khác còn thiếu kinh nghiệm quản lí, kiểm sóat chất lượng các dự án của công ty (đối với ứng viên có nhiều kinh nghiệm và định hướng làm BrSE Leader/ Delivery Manager).",
            },
            {
              title: "YÊU CẦU CÔNG VIỆC",
              description:
                "　• Tốt nghiệp chuyên ngành CNTT/Khoa học máy tính hoặc liên quan.\n　• Có kinh nghiệm trực tiếp tham gia vào các dự án với vai trò là kĩ sư cầu nối/quản lí dự án từ ~3 năm trở lên.\n　• Tiếng Nhật N1 hoặc tương đương N1.\n　• 	Có khả năng phân tích yêu cầu và xây dựng hệ thống.\n　•	Có khả năng Leadership là một lợi thế.\n　• Tinh thần trách nhiệm cao trong công việc.",
            },
            {
              title: "QUYỀN LỢI",
              description:
                "- Lương:\n　•	Thử việc 1 tháng 100% lương\n　•	Tăng lương 2 lần/năm (Mức lương tăng áp dụng ngay vào tháng 06 & tháng 12 của năm)\n\n- Thưởng: \n　•	Đối với thành viên: Thưởng 2 lần/năm: tối đa 0.5 tháng lương vào tháng 06 + 1 tháng lương vào tháng 12 đối với (hệ số theo lương mới được tăng).\n　•	Đối với cấp quản lí: Thưởng 2 lần/năm: tối đa 1 tháng lương vào tháng 06 + tối đa 2 tháng lương vào tháng 12 (hệ số theo lương mới được tăng).\n\n- Phúc lợi khác: \n　• 13 ngày nghỉ phép/năm.\n　• Bóng đá cty 1 tuần/lần; hoạt động Esport; Team Building; tiệc sinh nhật, du lịch công ty\n　• Quà thưởng sinh nhật, Lễ, Tết,…\n　• 	Các chế độ khác theo Luật định.\n　• Free snacks, café, trà…\n\n- Chế độ đào tạo:\n　•	Trợ cấp ngoại ngữ, trợ cấp khóa học và thưởng thi đỗ chứng chỉ, bằng cấp quốc tế\n　•	Free Udemy Course; lớp học ngoại ngữ; seminar, talkshow chia sẻ kinh nghiệm; mời chuyên gia đào tạo trực tiếp tại cty\n　•	Chương trình TGL Go To Japan – Học tập và làm việc tại Nhật Bản\n　• Lớp học Tiếng Anh, Tiếng Nhật trực tiếp tại công ty.\n\n- Môi trường làm việc:\n　•	Thời gian làm việc linh hoạt (làm việc từ Thứ Hai đến Thứ Sáu), WFH 5 ngày/tháng\n　•	Môi trường làm việc trẻ, thoải mái và đồng nghiệp supportive lẫn nhau\n　•	Công ty luôn thúc đầy tinh thần học tập & tạo mọi điều kiện cho nhân viên phát huy thế mạnh bản thân, định hướng sự nghiệp.",
            },
          ],
        },
        {
          id: 2,
          level: "All levels",
          position: "LTV .NET C#",
          sub: "Cạnh tranh với thị trường",
          extra: "Linh hoạt",
          content: [
            {
              title: "MÔ TẢ CHUNG",
              description:
                "- Nội dung chính:\n　• Phát triển các commands theo các tài liệu định nghĩa yêu cầu (document, specification)\n　• Tham khảo video dự án đính kèm: \nhttps://www.youtube.com/watch?v=opHXXkdOC5w&list=PLYWZmjpuHQMxs-FMWjBpNYmxvDqAQNA7E\n\n- Lí do nên tham gia vào dự án?\n　•	Được rèn luyện tư duy logic về Code: Coding 1 cách logic, khoa học và dễ hiểu nhất\n　•	Được tham gia vào quy trình phát triển phần mềm toàn diện: từ phân tích yêu cầu ban đầu đến deploy sản phẩm\n　•	Học hỏi được nhiều kỹ thuật phát triển và quy tắc thiết kế mã nguồn.\n　•	Rèn luyện được flow làm việc cho bản thân, cách thức xử lí & quản lí documents, cách làm việc nhóm hiệu quả: Giao tiếp với Comtors/ Testers/Team Leader/Project Leader và khách hàng\n\n- Mô tả công việc:\n　•	Phát triển các commands theo các tài liệu định nghĩa yêu cầu (document, specification) \n　•	Thực hiện tìm hiểu, thiết kế, estimate thời gian hoàn thành và coding command được phân công\n　•	Thực hiện Self-check/ Unit Test theo tài liệu Spec có sẵn\n　•	Update lại logic code hoàn chỉnh theo các Feedback từ Senior/ Project Leader\n　•	Thực hiện fix bugs được report từ Tester và Khách hàng\n　•	Hoàn chỉnh command được phân công và báo cáo lại với Project Leader\n　•	Tham gia Meeting hàng tuần để báo cáo tiến độ, khó khăn và kế hoạch\n　•	Thực hiện các công việc theo yêu cầu của TL/DM theo tiêu chí Smart và Speed",
            },
            {
              title: "YÊU CẦU CÔNG VIỆC",
              description:
                "　•	Tốt nghiệp cử nhân chuyên ngành CNTT/Khoa học máy tính hoặc liên quan\n　•	Nắm vững lập trình hướng đối tượng OOP\n　•	Có kinh nghiệm lập trình ngôn ngữ C#\n　•	Có thể xây dựng và thiết kế form bằng WPF\n　•	Có kĩ năng tốt về cấu trúc dữ liệu và thuật toán\n　•	Tư duy logic và khả năng giải quyết vấn đề tốt\n　•	Có khả năng làm việc nhóm và làm việc độc lập\n　•	Tinh thần tìm tòi giải quyết vấn đề đến cùng, tính trách nhiệm cao trong công việc.",
            },
            {
              title: "QUYỀN LỢI",
              description:
                "- Lương:\n　•	Thử việc 1 tháng 100% lương\n　•	Tăng lương 2 lần/năm (Mức lương tăng áp dụng ngay vào tháng 06 & tháng 12 của năm)\n\n- Thưởng: \n　•	Thưởng 2 lần/năm: tối đa 0.5 tháng lương vào tháng 06 + 1 tháng lương vào tháng 12. Chú ý: Lương dùng căn cứ để tính thưởng là lương mới sau khi review.\n\n- Chế độ đào tạo:\n　•	Trợ cấp ngoại ngữ, trợ cấp khóa học và thưởng thi đỗ chứng chỉ, bằng cấp quốc tế\n　•	Free Udemy Course; lớp học ngoại ngữ; seminar, talkshow chia sẻ kinh nghiệm; mời chuyên gia đào tạo trực tiếp tại cty\n　•	Chương trình TGL Go To Japan – Học tập và làm việc tại Nhật Bản\n\n- Môi trường làm việc:\n　•	Thời gian làm việc linh hoạt (làm việc từ Thứ Hai đến Thứ Sáu), WFH 5 ngày/tháng\n　•	Môi trường làm việc trẻ, thoải mái và đồng nghiệp supportive lẫn nhau\n　•	Công ty luôn thúc đầy tinh thần học tập & tạo mọi điều kiện cho nhân viên phát huy thế mạnh bản thân, định hướng sự nghiệp.",
            },
          ],
        },
        {
          id: 3,
          level: "Không yêu cầu kinh nghiệm",
          position: "LTV C++",
          sub: "Cạnh tranh với thị trường",
          extra: "Linh hoạt",
          content: [
            {
              title: "MÔ TẢ CHUNG",
              description:
                "- Nội dung chính:\n　•	Tạo các API Cho hệ thống CAD dựa trên nền tảng ODA (bằng C++/CLI) cho phép tiếp tục phát triển các command bằng các API này.\n　•	Phát triển các commands theo các tài liệu định nghĩa yêu cầu (document, specification) \n\n- Lí do nên tham gia vào dự án?\n　•	Được rèn luyện tư duy logic về Code: Coding 1 cách logic, khoa học và dễ hiểu nhất\n　•	Được tham gia vào quy trình phát triển phần mềm toàn diện: từ phân tích yêu cầu ban đầu đến deploy sản phẩm\n　•	Học hỏi được nhiều kỹ thuật phát triển và quy tắc thiết kế mã nguồn.\n　•	Rèn luyện được flow làm việc cho bản thân, cách thức xử lí & quản lí documents, cách làm việc nhóm hiệu quả: Giao tiếp với Comtors/ Testers/Team Leader/Project Leader và khách hàng\n\n- Mô tả công việc:\n　•	Phát triển các API theo các tài liệu định nghĩa yêu cầu (document, specification) \n　•	Thực hiện tìm hiểu, thiết kế, estimate thời gian hoàn thành và coding command được phân công\n　•	Thực hiện Self-check/ Unit Test theo tài liệu Spec có sẵn\n　•	Update lại logic code hoàn chỉnh theo các Feedback từ Senior/ Project Leader\n　•	Thực hiện fix bugs được report từ Tester và Khách hàng\n　•	Hoàn chỉnh command được phân công và báo cáo lại với Project Leader\n　•	Tham gia Meeting hàng tuần để báo cáo tiến độ, khó khăn và kế hoạch",
            },
            {
              title: "YÊU CẦU CÔNG VIỆC",
              description:
                "　• Tốt nghiệp cử nhân chuyên ngành CNTT/Khoa học máy tính hoặc liên quan\n　•	Sử dụng thành thạo ngôn ngữ lập trình C/C++.\n　•	Có kiến thức về lập trình hướng đối tượng OOP, đã từng làm việc với thư viện ObjectArx là một lợi thế.\n　•	Có kĩ năng tốt về cấu trúc dữ liệu và thuật toán\n　•	Tư duy logic và khả năng giải quyết vấn đề tốt\n　•	Có khả năng làm việc nhóm và làm việc độc lập\n　•	Tinh thần tìm tòi giải quyết vấn đề đến cùng, tính trách nhiệm cao trong công việc.",
            },
            {
              title: "QUYỀN LỢI",
              description:
                "- Lương: \n　•	Thử việc 1 tháng 100% lương\n　•	Tăng lương 2 lần/năm (Mức lương tăng áp dụng ngay vào tháng 06 & tháng 12 của năm)\n\n-	Thưởng: \n　•	Thưởng 2 lần/năm: tối đa 0.5 tháng lương vào tháng 06 + 1 tháng lương vào tháng 12. Chú ý: Lương dùng căn cứ để tính thưởng là lương mới sau khi review.\n\n-	Chế độ đào tạo:\n　•	Trợ cấp ngoại ngữ, trợ cấp khóa học và thưởng thi đỗ chứng chỉ, bằng cấp quốc tế\n　•	Free Udemy Course; lớp học ngoại ngữ; seminar, talkshow chia sẻ kinh nghiệm; mời chuyên gia đào tạo trực tiếp tại cty\n　•	Chương trình TGL Go To Japan – Học tập và làm việc tại Nhật Bản\n\n-	Môi trường làm việc:\n　•	Thời gian làm việc linh hoạt (làm việc từ Thứ Hai đến Thứ Sáu)\n　•	Môi trường làm việc trẻ, thoải mái và đồng nghiệp supportive lẫn nhau\n　•	Công ty luôn thúc đầy tinh thần học tập & tạo mọi điều kiện cho nhân viên phát huy thế mạnh bản thân, định hướng sự nghiệp.",
            },
          ],
        },
        {
          id: 4,
          level: "Từ 1 năm KN",
          position: "LTV BACK - END (NODEJS)",
          sub: "Cạnh tranh với thị trường",
          extra: "Linh hoạt",
          content: [
            {
              title: "MÔ TẢ CHUNG",
              description:
                "- Cơ hội khi tham gia vào dự án:\n　1.	Quy trình phát triển dự án theo mô hình Agile, làm việc với CI/CD,AWS\n　2.	Có thể tham gia vào các dự án với nhiều ngôn ngữ BE khác nhau (nếu có nhu cầu) để tích lũy thêm kinh nghiệm: C#, Nodejs, PHP\n　3.	Tham gia trực tiếp vào quy trình phát triển phần mềm ngay từ đầu để hiểu về Business, thiết kế cấu trúc data\n\n- Mô tả công việc:\n　•	Tham gia vào Bộ phận Cloud Solutions - phát triển Website của công ty với domain đa dạng: E - com, hệ thống nghiệp vụ, AR, livestream, web giáo dục…\n　•	Chịu trách nhiệm phát triển website về phần Back-end, phân tích, xử lý giải quyết các vấn đề về hệ thống của dự án.\n　•	Review code chéo giữa các member khác trong team.\n　•	Viết các document liên quan đến dự án.\n　•	Phối hợp với các thành viên trong nhóm để làm sản phẩm với chất lượng cao.\n　•	Phối hợp với các front-end developers và web designers để phân tích yêu cầu thiết kế phát triển các tính năng deploy sản phẩm theo yêu cầu của khách hàng\n　•	Hỗ trợ các thành viên mới, các thành viên thiếu kinh nghiệm hơn trong Team dự án (đối với các thành viên đã có KN)\n　•	Trao đổi, bàn bạc với cấp trên để đưa ra phương hướng phát triển dự án, phát triển Team của mình\n　•	Tham gia meeting dự án định kì, meeting bộ phận hàng tuần",
            },
            {
              title: "YÊU CẦU CÔNG VIỆC",
              description:
                "　•	Đã có kinh nghiệm làm việc với công nghệ NodeJS (biết về NestJS là một điểm cộng)\n　•	Có kinh nghiệm sử dụng các dịch vụ Cloud như AWS ( hoặc Microsoft Azure, Google Cloud Platform, ...)\n　•	Thuần thục viết Restful API, biết về GraphQL là một điểm cộng\n　•	Đã làm một trong các CSDL quan hệ : Oracle SQL, MySQL, SQL Server\n　•	Hiểu về Object Relational Mapping (ORM) và làm việc được với các thư viện như: TypeOrm, Entity Framework,...\n　•	Cấu hình được các web server như: IIS, Nginx và các tool hỗ trợ như: Visual studio, Visual StudioCode, GIT, Sourcetree\n　•	Có khả năng tư duy logic, giải quyết vấn đề, làm việc nhóm",
            },
            {
              title: "QUYỀN LỢI",
              description:
                "-	Lương: \n　•	Thử việc 1 tháng 100% lương\n　•	Tăng lương 2 lần/năm (Mức lương tăng áp dụng ngay vào tháng 06 & tháng 12 của năm)\n\n-	Thưởng: \n　•	Thưởng 2 lần/năm: tối đa 0.5 tháng lương vào tháng 06 + 1 tháng lương vào tháng 12. Chú ý: Lương dùng căn cứ để tính thưởng là lương mới sau khi review.\n\n-	Chế độ đào tạo:\n　•	Trợ cấp ngoại ngữ, trợ cấp khóa học và thưởng thi đỗ chứng chỉ, bằng cấp quốc tế\n　•	Free Udemy Course; lớp học ngoại ngữ; seminar, talkshow chia sẻ kinh nghiệm; mời chuyên gia đào tạo trực tiếp tại cty\n　•	Chương trình TGL Go To Japan – Học tập và làm việc tại Nhật Bản\n\n-	Môi trường làm việc:\n　•	Thời gian làm việc linh hoạt (làm việc từ Thứ Hai đến Thứ Sáu)\n　•	Môi trường làm việc trẻ, thoải mái và đồng nghiệp supportive lẫn nhau\n　•	Công ty luôn thúc đầy tinh thần học tập & tạo mọi điều kiện cho nhân viên phát huy thế mạnh bản thân, định hướng sự nghiệp.",
            },
          ],
        },
        {
          id: 5,
          level: "Từ 0-12 tháng kinh nghiệm",
          position: "LTV MOBILE",
          sub: "Cạnh tranh với thị trường",
          extra: "Linh hoạt",
          content: [
            {
              title: "MÔ TẢ CHUNG",
              description:
                "　• Tham gia vào Team phát triển app smart phone của công ty.\n　• Tham gia vào quy trình phát triển dự án ngay từ đầu, phân tích yêu cầu, thiết kế, coding và deploy sản phẩm.\n　• Nhận tasks, estimate thời gian hoàn thành, thực hiện coding, self test và deploy sản phẩm.\n　• Tham gia Meeting hàng ngày/tuần để báo cáo tiến độ và thảo luận các vấn đề liên quan đến dự án.",
            },
            {
              title: "YÊU CẦU CÔNG VIỆC",
              description:
                "　• Đã hoàn thành chương trình học chuyên ngành CNTT/Khoa học máy tính hoặc liên quan.\n　• Có kiến thức/ kinh nghiệm về React Native.\n　• Có kiến thức về backend .NET là lợi thế.\n　• Tư duy logic và khả năng giải quyết vấn đề tốt.\n　•	Có khả năng làm việc nhóm và làm việc độc lập.\n　• Tinh thần tìm tòi giải quyết vấn đề đến cùng, tính trách nhiệm cao trong công việc.",
            },
            {
              title: "QUYỀN LỢI",
              description:
                "- Lương:\n　•	Thử việc 1 tháng 100% lương\n　•	Tăng lương 2 lần/năm (Mức lương tăng áp dụng ngay vào tháng 06 & tháng 12 của năm)\n\n- Thưởng: \n　•	Thưởng 2 lần/năm: tối đa 0.5 tháng lương vào tháng 06 + 1 tháng lương vào tháng 12 (hệ số theo lương mới được tăng).\n\n- Thưởng khác: \n　• Thưởng theo dự án (tùy vào quy mô dự án).\n　• Thưởng Cá Nhân/ Team xuất sắc định kỳ 4 lần/ năm.\n　• Thưởng đặc biệt cuối năm: 60 triệu đồng/ người (cho 3 thành viên xuất sắc nhất).\n\n- Phúc lợi khác: \n　• 13 ngày nghỉ phép/năm.\n　• Bóng đá cty 1 tuần/lần; hoạt động Esport; Team Building; tiệc sinh nhật, du lịch công ty\n　• Quà thưởng sinh nhật, Lễ, Tết,…\n　• 	Các chế độ khác theo Luật định.\n　• Free snacks, café, trà…\n\n- Chế độ đào tạo:\n　•	Trợ cấp ngoại ngữ, trợ cấp khóa học và thưởng thi đỗ chứng chỉ, bằng cấp quốc tế\n　•	Free Udemy Course; lớp học ngoại ngữ; seminar, talkshow chia sẻ kinh nghiệm; mời chuyên gia đào tạo trực tiếp tại cty\n　•	Chương trình TGL Go To Japan – Học tập và làm việc tại Nhật Bản\n　• Lớp học Tiếng Anh, Tiếng Nhật trực tiếp tại công ty.\n\n- Môi trường làm việc:\n　•	Thời gian làm việc linh hoạt (làm việc từ Thứ Hai đến Thứ Sáu), WFH 5 ngày/tháng\n　•	Môi trường làm việc trẻ, thoải mái và đồng nghiệp supportive lẫn nhau\n　•	Công ty luôn thúc đầy tinh thần học tập & tạo mọi điều kiện cho nhân viên phát huy thế mạnh bản thân, định hướng sự nghiệp.",
            },
          ],
        },
      ],
    },

    keyBenefit: {
      title: "Những ưu thế khi trở thành Penguin",
      cards: {
        internal: {
          title: "Đào tạo nội bộ",
          sub: 'Với tầm nhìn "Nâng tầm giá trị con người Việt", TGL Solutions luôn chú trọng các hoạt động đào tạo nội bộ, tổ chức các talkshow, seminar chia sẻ kinh nghiệm, mời chuyên gia đào tạo trực tiếp tại công ty. ',
        },
        hours: {
          title: "Thời gian làm việc linh động",
          sub: "Tự do, thoải mái làm việc theo đúng đồng hồ sinh học của bản thân",
        },
        salary: {
          title: "Mức lương hấp dẫn",
          sub: "Tăng lương 2 lần/1 năm và thưởng 2 lần/1 năm",
        },
        activity: {
          title: "Hoạt động công ty",
          sub: "Các câu lạc bộ thể thao (bóng đá, cầu lông,...), hoạt động workshop, happy hour hàng tháng, du lịch toàn công ty hằng năm.",
        },
        workhome: {
          title: "Làm việc tại nhà",
          sub: "Mỗi nhân viên đều có 5 ngày hằng tháng được chọn làm việc tại nhà không cần lên văn phòng",
        },
        environment: {
          title: "Môi trường lành mạnh",
          sub: "Tôn trọng - Hòa đồng - Thoải mái là ba điều bạn sẽ được trải nghiệm mỗi ngày tại TGL Solutions.",
        },
      },
    },

    poem: { title: "Hãy làm việc thật vui vẻ, có vui vẻ thì mới làm việc tốt" },

    welcome: {
      title: "ĐỒNG HÀNH SỨ MỆNH NÂNG TẦM GIÁ TRỊ CON NGƯỜI VIỆT!",
      sub: "TGL Solutions luôn tìm kiếm những con người đam mê thử thách để tạo nên những giá trị.",
      btn: "Cùng nhau phát triển",
    },

    detailJobs: {
      benefitPackage: [
        {
          title: "Gói phúc lợi",
          sub: null,
        },
        {
          title: "Chăm sóc sức khỏe tinh thần",
          sub: [
            "Tổ chức khám sức khỏe nhân viên hằng năm",
            "Quà tặng nhân viên vào các dịp đặc biệt",
            "Khuyến khích tham gia các hoạt động thể thao",
          ],
        },
        {
          title: "Học tập và đào tạo",
          sub: [
            "Tổ chức các Seminar, hội thảo hằng tuần",
            "Khả năng phát triển và thử thách ở mọi vị trí công việc",
          ],
        },
        { title: null, sub: [] },
        {
          title: "Sự kiện",
          sub: [
            "Tổ chức sinh nhật cho nhân viên và Happy Hour hàng tháng",
            "Cung cấp budget cho mỗi team hoạt động vui chơi",
            "Du lịch dã ngoại hằng năm, tiệc tất niên cuối năm",
          ],
        },
        {
          title: "Khen thưởng",
          sub: [
            "Tổ chức TGL Spot Award hằng quý để khen thưởng những tập thể xuất sắc",
            "Giải thưởng TGL Award khen tặng 3 cá nhân và 1 tập thể vào cuối năm",
          ],
        },
        { title: null, sub: [] },
        { title: null, sub: [] },
        {
          title: "Cơ sở vật chất",
          sub: [
            "Coffee, bánh kẹo luôn có sẵn ở văn phòng",
            "Cung cấp thiết bị dụng cụ làm việc",
            "Văn phòng công ty nằm ở khu vực trung tâm TPHCM, nhiều hàng quán và thuận tiện di chuyển",
          ],
        },
      ],

      similarOpportunities: {
        title: "Các vị trí khác",
        cards: [
          {
            title: "Front-end Engineer",
            position: "Junior",
            sub: "Cạnh tranh với thị trường",
          },
          {
            title: "Front-end Engineer",
            position: "Junior",
            sub: "Cạnh tranh với thị trường",
          },
          {
            title: "Front-end Engineer",
            position: "Junior",
            sub: "Cạnh tranh với thị trường",
          },
        ],
      },
    },
  },

  contact: {
    title: "Liên hệ chúng tôi nhé!",
    welcome:
      "Đồng hành cùng TGL trở thành chú chim tiên phong tạo nên giá trị!",
    sub: "Hãy điền những thông tin sau để TGL có cơ hội được đồng hành cùng bạn, những chú Penguin của chúng tôi sẽ sắp xếp phản hồi thông tin trong khoảng thời gian sớm nhất",
    cards: [
      {
        title: "Địa chỉ ",
        sub: [
          "VP tầng 5, BCONS Tower 2, 42/1 Đường Ung Văn Khiêm, Phường 25, Quận Bình Thạnh, Thành phố Hồ Chí Minh, Việt Nam",
        ],
      },
      {
        title: "Mở cửa ",
        sub: ["Thứ Hai - Thứ Sáu: 8h30-18h00", "Thời gian làm việc linh động"],
      },
      {
        title: "Số điện thoại ",
        sub: ["028 2200 2019"],
      },
      {
        title: "Địa chỉ Email",
        sub: ["hr@tgl-sol.com"],
      },
    ],
    dataFormMail: {
      title: "Liên hệ chúng tôi",
      subTitle:
        'Với phương châm "Xây dựng công ty vững mạnh lấy con người làm trung tâm", mọi chính sách, quyết định của công ty đều',
      name: "Họ và Tên",
      email: "Email",
      phone: "Số điện thoại",
      skills: "Kỹ năng",
      birthday: "Ngày sinh",
      website: "Trang web ...",
      network: "Facebook, LinkedIn ...",
      other: "Khác",
      message: "Lời nhắn của bạn",
      upload_file: "File",
      upload_cv: "+ Tải file CV",
      upload: "+ Tải file",
      content_cv:
        "Đăng tải tối đa 1 tập tin có định dạng .PDF/.DOC/.DOCX/.JPG/.PNG/.ZIP với dung lượng không quá 10MB mỗi tập tin.",
      submit: "Gửi đi",
      status: "Trạng Thái",
      success: "Đã gửi thành công!",
      fail: "Gửi thất bại!",
      file_wrong: "Chỉ chấp nhận định dạng pdf, doc, docx.",
      file_success: "File đã được tải thành công",
      file_size: "Kích thước tệp quá lớn. Vui lòng chọn tệp nhỏ hơn 10MB",
      required: {
        name: "Vui lòng nhập tên",
        error_name: "Tên chỉ chứa ký tự chữ và khoảng trắng",
        email: "Vui lòng nhập email",
        birthday: "Vui lòng nhập ngày sinh",
        phone: "Vui lòng nhập số điện thoại",
        cv: "Vui lòng upload CV",
        error_number: "Số điện thoại từ 10-12 số",
        error_email: "Email không hợp lệ",
        skill: "Vui lòng chọn kỹ năng",
        error_birthday: "Ngày sinh không hợp lệ",
      },
      other_file: "+ Tải file khác",
      other_file_list: "Danh sách file:",
      delete: "Xoá",
    },
  },

  footer: {
    award: {
      title: "Chất lượng & Bảo mật",
      description: "là ưu tiên hằng đầu của chúng tôi",
    },
    contents: [
      {
        title: "Văn phòng",
        content11: "Tầng 5, Bcons II Tower",
        content12: "42/1 Ung Văn Khiêm, Phường 25, Quận Bình Thạnh, TP.HCM",
        ref1: "https://www.google.com/maps/place/TGL+Solutions/@10.803317,106.7129046,17z/data=!3m1!4b1!4m5!3m4!1s0x3175296c9e4131e9:0xf693c3afeb7bfe46!8m2!3d10.8033117!4d106.7150933",
      },
      {
        title: "Liên hệ",
        content1: "hr@tgl-sol.com",
        ref1: "mailto:hr@tgl-sol.com?subject=subject&body=body",
        content2: "028 2200 2019",
        ref2: "tel: 02822002019",
      },
      {
        title: "Mạng xã hội",
        content1: "TGL Solutions",
        ref1: "https://www.facebook.com/TGLSolutions",
        content2: "https://tgl-sol.com",
        ref2: "/",
        content3: "TGL TV",
        ref3: "https://www.youtube.com/channel/UCyhiHfIqwnXJhoGVKmlUEPA",
      },
    ],
  },

  detailJobs: {
    salary: "Mức lương",
    experience: "Kinh nghiệm",
    level: "Vị trí",
  },
};

export default vn;
