const vn = {
  lang: {
    vietnam: "Vietnamese",
    english: "English",
    japannese: "Japanese",
  },
  seo: {
    home: {
      title: "Home | TGL Solutions - Smart & Speed Solution",
      description:
        "Driven by the motto: “Creating strong people-oriented company”, all policies and decisions totally aim at creating the most comfortable working environment for employees to promote their potentials, strong points and occupational orientation.",
      keywords:
        "TGL Solutions, Software Company, Software Solutions, Advanced Technology, Web Applications, Mobile Applications, AI, Metaverse, AutoCAD, Websites, Applications, Mobile",
    },
    about_us: {
      title: "About us | TGL Solutions - Smart & Speed Solution",
      description: "Elevate the value of Vietnamese people in the world!",
    },
    why_us: {
      title: "People | TGL Solutions - Smart & Speed Solution",
      description: "The story of the “pioneer penguins” by TGL Soluitons",
    },
    product: {
      title: "Product | TGL Solutions - Smart & Speed Solution",
      description:
        "TGL Solutions is committed to completing projects with guaranteed quality as fast as possible to meet the requirements. That is the reason why the list of current customers who want to continue cooperating with us is constantly growing.",
    },
    contact: {
      title: "Contact us | TGL Solutions - Smart & Speed Solution",
      description:
        "Join TGL SOLUTIONS to be a pioneer penguin in creating value!",
    },
    recruiment: {
      title: "Recruitment | TGL Solutions - Smart & Speed Solution",
      description: "Let’s start your journey with TGL Solutions!",
    },
  },
  decoration_house: {
    title: "Smart AI For House Decoration",
    subtitle:
      "Redesign any space to match your vision, or let our AI show you inspiring designs in seconds.",
    optionDecoration: "Choose your design",
    exterior: "Exterior",
    interior: "Interior",
    labelUpload: "Upload house image",
    labelSample: "Select a sample",
    theme: "Select style",
    number: "Choose number of pictures",
    btnAi: "Generate new design",
    note: "Note: The larger the number of results, the slower the processing speed.",
    result: "The image after AI design",
    label: "Design options",
    contentChoosePic: "OR",
    queueBefore: "There are currently",
    queueAfter: "people ahead of you. Please wait a moment.",
    labelCountdown: "Results will be available in",
    inProgress: "The system is processing your image",
    back: "Back",
    download: "Download",
    zoom: "Zoom",
    upload: "Upload image",
    origin: "Origin",
    output: "After design",
    reset: "Reset",
    step1: "Step 1. ",
    step2: "Step 2. ",
    step3: "Step 3. ",
    step4: "Step 4. ",
    ex: "Example: ",
    drag: "Drag to experience",
    close: "Close",
  },
  decoration_house_4season: {
    title: "Exterior design of the house based on the blueprint",
    subtitle:
      "Creatively design according to your preferences, incorporating a four-season landscape",
    optionDecoration: "Choose your design",
    exterior: "Exterior",
    interior: "Interior",
    labelUpload: "Upload house image",
    labelSample: "Select a sample",
    theme: "Input style",
    number: "Choose number of pictures",
    btnAi: "Generate new design",
    note: "Note: The larger the number of results, the slower the processing speed.",
    result: "The image after AI design",
    label: "Design options",
    contentChoosePic: "OR",
    queueBefore: "There are currently",
    queueAfter: "people ahead of you. Please wait a moment.",
    labelCountdown: "Results will be available in",
    inProgress: "The system is processing your image",
    back: "Back",
    download: "Download",
    zoom: "Zoom",
    upload: "Upload image",
    origin: "Origin",
    output: "After design",
    reset: "Reset",
    step1: "Step 1. ",
    step2: "Step 2. ",
    step3: "Step 3. ",
    step4: "Step 4. ",
    drag: "Drag to experience",
    close: "Close",
    ex: "Example: ",
    spring: "Spring",
    summer: "Summer",
    autumn: "Autumn",
    winter: "Winter",
    numFloor: "Number of floor",
    errInput: "Invalid value",
  },
  header: {
    home: "Home",
    about_us: "About us",
    why_us: "Human",
    services: "Our Core Services",
    projects: "Project",
    projectsOther: "Other projects",
    activities: "Activities",
    contact: "Contact",
    recruiment: "Recruitment",
    product: "Product",
    blog: "Blog",
    news: "News",
    selectLang: "Select language",
  },
  video: {
    cad: {
      title: "AutoCAD Customization",
      sub: "This is a demo of developing commands individually and optimizing your piping work. ",
    },
    revit: {
      title: "REVIT Customization",
      sub: "This is a demo of automatic bar arrangement. It can greatly improve your business design.",
    },
    aiHouse: {
      title: "Smart AI for house design",
      sub: "Our AI system provides rapid redesign and customization of your living space, tailoring it to your preferences. Experience the new concept through our sample images and make informed decisions with confidence.",
      button: "Try it free",
    },
  },
  button: { show: "See more", hide: "See less", seeMore: "See more" },
  ceo: {
    name: "CEO Le Tien Trien",
    title_image_1: "CEO Le Tien Trien",
    title_image_2: "Dr. Trien received his doctorate in Japan in March 2018.",
    ceo_content_1:
      'In September 2019, CEO Dr. Trien, as an enthusiastic Vietnamese who always hopes to contribute to the society and the country as a whole, has founded TGL Solutions with the slogan "Smart & Speed Solutions". It means we will propose solutions in the smartest and the fastest way for our customers.',
    ceo_content_2:
      'How did "Smart" and "Speed" become so important in the fourth industrial revolution?',
    ceo_content_3:
      "We are so lucky to be living in the golden era of information technology (IT). Much like other golden eras in the past, this golden era began with the birth and popularity of the Internet (1995), and is assumed to last for about 50 years, which means that we are nearly in the middle of this golden era.",
    ceo_content_4:
      "In the early 2010s, the rapid development of artificial intelligence (AI) has given us techniques that seem to have only appeared in science fiction movies. The AI machines can now classify and recognize images with extreme accuracy, and make quick decisions. Not only that, AI has been appearing in all aspects of our daily lives with even more precision than humans, such as helping us improve smartphone photography, and handling shipping orders for faster delivery, etc.",
    ceo_content_5:
      'Thanks to the evolution of Cloud, AI has been much more easier to access, which significantly improved perfomance in many areas. And that means we must work smarter in order to keep up with the development of AI. That is the whole point of "Smart" in our Slogan.',
    ceo_content_6:
      'So what about "Speed"? If you are using a smartphone, congratulation! Because you are owning a supercomputer from about 25 years ago. It means that now everyone can easily own things that were dreamed of 25 years ago. Sciences and technologies are moving faster and faster, unless we carry out works quickly, make mistakes quickly, and correct them quickly, being left behind is inevitable. We put "Speed" not only in coming up with solutions, but also in making desicions, and correcting mistakes, which undoubtedly will lead to success.',
    title_content_1: "Some achievements of CEO Le Tien Trien",
    array_achievements: [
      {
        id: 1,
        description:
          "Winning second place of national gifted student in Mathematics in grade 12 and receiving the university’s direct admission.",
        year: "2005",
      },
      {
        id: 2,
        description:
          "Obtaining Japanese Government Scholarship (MEXT) to study abroad in Japan.",
        year: "2007",
      },
      {
        id: 3,
        description:
          "Renewed MEXT scholarship once, special renewal twice, totally reciving MEXT scholarship for 11 consecutive years.",
        year: "2007 - 2018",
      },
      {
        id: 4,
        description:
          "Obtaining a doctorate in electronics and telecommunications and information technology in Japan.",
        year: "2018",
      },
      {
        id: 5,
        description: "Senior Researcher at Hitachi Research Center.",
        year: "2018 - 2021",
      },
      {
        id: 6,
        description:
          "Achieving numerous awards for excellent performance at university as well as Hitachi Corporation.",
        year: "2013 - 2021",
      },
      {
        id: 7,
        description: "Having more than 10 published patent applications.",
        year: "2017 - 2021",
      },
      {
        id: 8,
        description:
          "Having many scientific articles published in prestigious international journals, and having attended conferences in many countries around the world such as Germany (2013), Canada (2014), China (2014, 2015, 2016), France (2017), USA (2019).",
        year: "2014 - 2019",
      },
    ],
    title_content_2: "Milestones in CEO Le Tien Trien career",
    img_timeline: "/images/timeline-ceo.png",
    img_timeline_mobile: "/images/timeline-ceo-mobile.png",
    milestones_content: [
      "Established skyACE Co.Ltd (Headquarters in Tokyo, Japan)",
      "Established TGL Solutions, Ho Chi Minh City",
      "Established Prima Solutions, Da Nang City,",
    ],
  },

  home: {
    banner: {
      subtitleStart: "With the motto of",
      subtitleBold:
        "“Building a sustainable company with people-centric culture”.",
      subtitleEnd:
        "All of company policies are made based on creating the most comfortable working environment, providing all necessary means for employees to be able work to their full potential, personal strengths, and career orientation.",
      btnContact: "Contact us",
    },
    process: {
      title: "Working process",
      cards: [
        {
          step: "Requirement Definition",
          content: [
            "Hearing requirement from customer",
            "Analyze requirement",
            "Design document and user interface",
          ],
        },
        {
          step: "Resources Allocation",
          content: [
            "Analyze and identify resources",
            "Make schedule and assign task",
          ],
        },
        {
          step: "Product development",
          content: [
            "Design detail document",
            "Develop",
            "Unit test",
            "Project Leader checks and reviews source code",
          ],
        },
        {
          step: "Bug verification and fixing",
          content: [
            "QC team verify products in 3 sections: module testing, system testing, user acceptance testing",
            "Developer fixs bugs and complete the product",
          ],
        },
        {
          step: "Product delivery",
          content: [
            "Deliver product to customer after completion",
            "Support operation and maintenance",
          ],
        },
      ],
    },
    service: {
      title: "Service",
      sub: "Since its establishment, TGL Solutions constantly exert relentless efforts to expand and diversify our software engineering services, catering to the evolving needs and development trends of the market.",
      cards: [
        {
          category: "Web System",
          title: "Website system development",
          content:
            "We offer comprehensive consulting solutions encompassing website design, programming, and optimization. Our expertise extends to integrating cutting-edge technologies such as VR, Livestream, and Auction applications directly into e-commerce platforms, thereby enhancing customer engagement and maximizing commercial efficiency.",
        },
        {
          category: "Metaverse",
          title: "Metaverse development",
          content:
            "By leveraging cutting-edge technologies such as Omniverse Nvidia, Actorcore, and more, we employ advanced simulations to replicate operational procedures and predict product or production process risks. This innovative approach significantly minimizes installation and manufacturing time, delivering remarkable efficiency gains.",
        },
        {
          category: "AI",
          title: "AI development",
          content:
            "The utilization of cutting-edge AI deep learning technology (DeepLabv3, HRnet) in medicine is poised to revolutionize the field by enabling early disease diagnosis and formulating treatment plans that extend patients lives while achieving superior treatment outcomes.",
        },
        {
          category: "Smartphone App",
          title: "Smartphone application development",
          content:
            "With extensive expertise in iOS and Android smartphone application development, we have successfully crafted a wide range of applications for our clients, including e-commerce platforms, social networking solutions, and comprehensive business management systems.",
        },
        {
          category: "PC Application",
          title: "PC application development",
          content:
            "The outstanding feature of TGL Solutions is its exceptional speed in creating demos for customer consideration. This enables us to consistently deliver application development solutions that align with our customers desired quality requirements.",
        },
        {
          category: "BIM/CAD",
          title: "BIM/CAD development",
          content:
            "We utilize the power of C/C# programming language to develop command functions for civil engineering, architectural, and mechanical drawings. Our expert team provides consulting services and tailor-made customization of CAD drawing features to optimize productivity and efficiency, precisely catering to our customers specific requirements.",
        },
        {
          category: "All",
          title: "Our project",
          content:
            "TGL Solutions is committed to completing projects with guaranteed quality as fast as possible to meet the requirements. That is the reason why the list of current customers who want to continue cooperating with us is constantly growing.",
        },
      ],
      button: "See details",
    },
    tglGroup: {
      title: "Global expansion from 2019",
      techStack: "Technologies",
      sub: "TGL Solutions is proud to be a trusted partner of many domestic and foreign customers, providing professional and effective technology solutions, which meet the customers expectations and demands.",
      content:
        "Dynamic, enthusiastic and creative working environment of young people",
      branch: [
        {
          name: "skyACE Co., Ltd.",
          sub: "Located in the capital city of Tokyo, Japan, skyACE (the parent company of TGL Group) is the workplace of a highly experienced team of IT professionals from various countries around the world.",
        },
        {
          name: "Prima Solutions",
          sub: "Established in April 2022 with a core focus on driving digital transformation within the construction industry, our company is dedicated to the development of cutting-edge CAD/BIM technology applications for the fields of architecture, construction, and mechanical engineering.",
        },
        {
          name: "TGL Solutions",
          sub: "Despite encountering numerous challenges since its inception, the company has demonstrated remarkable growth in both revenue and workforce. In 2022, revenue surged by an impressive 200% compared to the previous year, marking a significant milestone in the sustainable development of TGL Solutions.",
        },
      ],
    },
    ourCertifications: {
      title: "Our Awards & Certifications",
    },
    about: {
      title: "About us",
      sub: 'In September 2019, CEO Dr. Trien, as an enthusiastic Vietnamese who always hopes to contribute to the society and the country as a whole, has founded TGL Solutions with the slogan "Smart & Speed Solutions". It means we will propose solutions in the smartest and the fastest way for our customers.',
      btnView: "See more",
    },
    project: {
      title: "Our projects",
      sub: "TGL Solutions is committed to completing projects with guaranteed quality as fast as possible to meet the requirements. That is the reason why the list of current customers who want to continue cooperating with us is constantly growing.",
      btnView: "View all projects",
      clients: "Clients",
      companyProjects: "Projects",
    },
    contact: {
      title: "Contact us",
      sub: {
        span: "TGL Solutions strives to create an ideal environment where individuals feel empowered to contribute their unique value and drive positive change, fostering the sustainable development of both the company and society at large.",
        p: "",
      },
    },
    BIMCAD_highlight: {
      title: "Outstanding BIMCAD projects",
      button: "Watch demo",
      content1: {
        title: "PIPING Customization",
        sub: "This is a demo of developing commands individually and optimizing your piping work.",
      },
      content2: {
        title: "REVIT Customization",
        sub: "This is a demo of automatic bar arrangement. It can greatly improve your business design.",
      },
      content3: {
        title: "Smart AI for house design",
        sub: "Our AI system provides rapid redesign and customization of your living space, tailoring it to your preferences. Experience the new concept through our sample images!",
        button: "Try out",
      },
    },
  },

  about_us: {
    banner: {
      title: "Elevate the value of Vietnamese people in the world!",
      sub: 'With the motto "Building a strong and people-centric company", all policies and decisions of the company are aimed at building the most comfortable working environment, creating all conditions for employees to promote their potential, personal strengths, and career orientation.',
    },

    message: {
      title: "Fun workspace makes productive workspace",
      sub: {
        first: "There are two things I want to share with you.",
        second:
          'First of all, "Fun workspace makes productive workspace". As a matter of fact, if the employees work more efficiency, the company will undoubtedly grow further. In return, the employees will receive project bonus, pay raise, and other benefits. This will create a sustainable growth for the company, and afterward contributing to the community and society.',
        third:
          "Secondly, in the current world of rapid change, I believe not only the managers but also the employees feel confused, sometimes disoriented and do not know which is the most reasonable direction. In that situation, always keep in mind the spirit of 3T: Transit, Trial & Error, and Take the lead.",
        four: "",
      },
      acthor: "CEO, CO-FOUNDER, DR. LE TIEN TRIEN",
      btnView: "See more",
    },

    slogan: [
      "Choose the smartest and most effective way to solve problem.",
      "Make prompt decision for prompt correction. Do not hesitate anymore.",
      "Combine “Smart” and “Speed” will provide the most effective “Solutions” and bring the best value to customers.",
    ],

    vision: {
      title: "Vision and Mission",
      cards: [
        {
          title: "Vision",
          sub: "Elevating the value of Vietnamese people.",
          content:
            "With the people-centric culture, TGL Solutions emphasizes on nurturing, training, and developing people in problem-solving thinking, professional working style, spirit of constant challenge, and self-improvement. Thereby, TGL Solutions aims to elevate the value of Vietnamese people, helping us to undertake projects at a higher level in the global supply chain and contribute to the development of Vietnam.",
        },
        {
          title: "Mission",
          sub: "Decision and Actions are made considering employee’s benefits.",
          content:
            'In our opinion, people are the greatest asset of the company that helps the company to grow. The development of the company will increase the welfare of the employees and improve the working environment to create all conditions and motivation that help people grow. These create a "sustainable cycle" that pushes the company to reach a new level, and realizes TGL Solutions’ s Vision of "Elevating the value of Vietnamese people".',
        },
      ],
    },

    value: {
      title: "Core Values",
      sub: 'Based on the core value of "Concord among the people", the company always tries to build the most comfortable working environment, creating all conditions for employees to promote their potential and strengths.',
      cards: {
        people: {
          title: "The People",
          slogan: "S (Security) >> Q (Quality) > D (Delivery) > C (Cost)",
          subject:
            "The People is human. TGL Solutions’s principle is to build a strong, people-centric company.",
          content:
            "S (Security): Always prioritize information security for projects and customers. Security & Quality are TGL Solutions top priorities. S is the most and much more important than Q.\nNext is Q (Quality of the product): Always prioritize product quality over delivery time (D: Delivery) and cost (C: Cost).\nThat means, although it is the delivery day, if the product quality does not meet the requirements, quality improvement must be prioritized.",
        },
        piece: {
          title: "Harmony",
          subject:
            "It is the unanimity, and the positive mental attitude that TGL Solutions encourages all employees to improve day by day.",
          content:
            "TGL Solutions always encourages conflicting opinions and debate to find the optimal solution. Once the implementation plan has been decided, all members must agree on a common goal.",
        },
      },
    },

    rule: {
      title: "Work Principle",
      sub: "Do you know what TGL means in English?",
      cards: [
        {
          title: "Announcing",
          sub: '"T" stands for "Thong bao", which means "Announcing". When something changes, it should be announced to all concerned parties."',
        },
        {
          title: "Explaning",
          sub: '"G" stands for "Giai thich", which means "Explaning". When explaining a problem, it is necessary to clearly explain the purpose of the solutions, from the purpose, deduce the method.',
        },
        {
          title: "Discussing",
          sub: '"L" stands for "Luan ban", which means "Discussing". Raise the issues to everyone in the team, considering brainstorming will help solve the issues more comprehensively and effectively.',
        },
      ],
    },

    ourFirstPenguin: {
      title: "Board members",
      sub: "Together we grow.",
      cards: [
        {
          name: "Pham Phu Cuong",
          avatar: "/images/aboutus/Cuong.png",
          position: "CTO TGL Solutions",
          content:
            'My perspective is that working is about creating value for society. When you use a tool or service, that’s the value brought by someone else’s work. Working isn’t "selling oneself to capitalism"; it’s about contributing knowledge and skills to create value and earning a fair reward in return. Therefore, keep honing your abilities and enhancing your skills, so the value you create grows, your compensation increases, and you gain the satisfaction and recognition of those around you.',
        },
        {
          name: "Bui Si Hiep",
          avatar: "/images/aboutus/Hiep.png",
          position: "CAD Solutions Department Manager",
          content:
            "As the Head of the BIM/CAD software development team, my colleagues and I constantly innovate to deliver cutting-edge technology solutions that streamline construction processes and boost productivity for architects, engineers, and contractors. We believe that BIM/CAD technology will revolutionize the construction industry, and my team is relentlessly dedicated to making that vision a reality.",
        },
        {
          name: "Le Tien Trien",
          avatar: "/images/aboutus/Trien.png",
          position: "CEO/ CO-FOUNDER",
          content:
            "Prioritize action over waiting and observing, and raise your opinion instead of keeping silent. At TGL Solutions, as long as you present and share your purpose before doing anything, there is no wrong choice.",
        },
        {
          name: "Ngo Xuan Huy",
          avatar: "/images/aboutus/Huy.png",
          position: "Head of SPEED Department",
          content:
            '"Everything must follow the process" is the key to open any doors of technology, and overcome challenges. Not only do I follow the rules, but I always want to create a comfortable working environment for all members because I believe that only when people are healthy and mentally comfortable, can they do their tasks efficiently.',
        },
        {
          name: "Le Be Ly",
          avatar: "/images/aboutus/Ly.png",
          position: "HR Manager",
          content:
            "As the person in charge of the HR department at the company, I always try to ensure the benefit of the employees as my own. With all my efforts and enthusiasm, I hope TGL Solutions will be a place where everyone can learn together, work hard for their passion, have fun together and grow up together.",
        },
      ],
    },

    morale: {
      title: "TGL Spirit",
      cards: [
        {
          title: "Transit",
          sub: "Constantly change to “refresh” present. In order to change, different opinions must always be respected, employees are always encouraged to actively give opinions.",
        },
        {
          title: "Trial & error",
          sub: "The world is changing rapidly. Rather than procrastinating, prioritize action over waiting and observing, and raise your opinion instead of keeping silent. \nWhen you encounter failure, let’s sit back, analyze and find out the root cause, then find ways to fix it and improve it.",
        },
        {
          title: "Take the lead",
          sub: "Be the first penguin!\nBe the first penguin that dares to break through, dares to change, and that will inspire the whole team to change accordingly.",
        },
      ],
    },

    welcome: {
      title:
        "ACCOMPANYING THE MISSION OF ELEVATING THE VALUES OF THE VIETNAMESE PEOPLE!",
      sub: "TGL Solutions actively seeks individuals who are passionate about embracing challenges to drive value creation.",
      btn: "Together we grow",
    },
  },

  people: {
    banner: {
      title: "TGL Solutions’s story of the “pioneer penguins”",
      sub: "There are more than 19 species of “Penguin” in the world but the most popular “Penguin” at TGL is the “First Penguin” - a lovely, persevering, and a brave fighter.",
    },

    firstPenguin: {
      btn: {
        seeMore: "See more",
        seeLess: "See less",
      },
      data: {
        title: "The First Penguin",
        welContent:
          "There are more than 19 species of “Penguin” in the world but the most popular “Penguin” at TGL is the “First Penguin” - a lovely, persevering, and a brave fighter.",
        sub: '\nDuring the hunting season, all the penguins gather at the top of the iceberg and look out to sea to see if there are any fish or danger nearby. However, not every penguins can overcome their fear of jumping first. They scare of the far distance from the snout to the sea; the unforseen risks of catching nothing or being attacked, etc.\n\n Which penguin will make the jump?\n\n The “First Penguin” chose to be the vanguard. After observing that it was no big deal, the 2nd, 3rd, 4th, and 5th penguins also jumped into the sea.\n\n Make no mistake – the “First Penguin” is neither stupid nor reckless. Jumping into the ocean is always a risk but a calculated one, because it will survive by any means. “First Penguin - First Eat” is the achievement for only the "First Penguin". Not only that, when a leading penguin dares to break through, it will bring a group to change.',
        question:
          'Somebody has got to be "The First Penguin".\nWhy not me?\nWhy not you?',
      },
      poem: [
        {
          title:
            '“If you are not the "First Penguin", be the second one. If you are neither the first nor the second this time, be the first next time.”',
          sub1: 'TGL Solutions uses the word "Penguin" for all members to encourage them to "Be the First Penguin", dare to change, get out of their comfort zone, take risks and become pioneers. Instead of procrastinating thinking about how to become “First Penguins”, take the first steps:',
          sub2: "Make a change in the way we work, the things that we are so familiar with in daily life. Change means taking risks and making mistakes. You must be willing to accept trial and error many times. After all, you can fail, but it will also make a difference and can take things in a new, stronger direction that will help you become a Pioneer in your field.",
          show: "See more",
          hide: "See less",
        },
        {
          title:
            "“Set goals with all faith.\nDesign for Failure.\nEnjoy surprises along the way.”",
          sub1: "TGL Solutions has also been encouraging and honoring The First Penguins who dare to change and make breakthroughs in the form of the award “Be the first Penguin (one of the three biggest TGL Awards of the year, the prize value is one trip to Japan within 1 week).\n\nThis is one of the ways for the Board of Directors to encourage Penguins to change, discover different things, and always strive to become the best possible version of yourself.",
          sub2: "And while all the efforts of the pioneer Penguins are not always successful, that is still okay. Why? \n\nBecause no matter who you are, as long as in the process of getting out of your comfort zone, you are actually becoming a better version through learning from mistakes, which contributes to a good transformation for the whole group. \n\nTransforming, Trial and Error, and Taking the lead is the 3T spirit that the company want to convey through the image of Penguins - the mascot of TGL Solutions.",
          show: "See more",
          hide: "See less",
        },
      ],
    },

    studyDevelop: {
      title: "Learning & Developing",
      sub: 'With the motto "Building a strong people-centric company", investment in skill development, training organization, etc. is our top priority.',
      data: [
        {
          step: "Japanese Class",
          sub: "At TGL Solutions, the majority of customers come from the Japanese market, so understanding Japanese will be one of the keys to making projects run more smoothly.\n\nJapanese courses at TGL Solutions are taken place at the office, taught by a native teacher and teaching assistants with N1, N2 JLPT qualifications graduated from the Japanese language deparment.",
        },
        {
          step: "English Class",
          sub: "Currently, English class is taking place at the company’s office after working hours to ensure the most convenience for all employees. The class is divided into two different levels corresponding to the desires and skills of each member. Advanced class will be taught directly by a native teacher.",
        },
        {
          step: '"Go to Japan" Study Program',
          sub: "Not only encouraging employees to learn Japanese, TGL Solutions also helps realize the Japanese dream of Penguins who want to change, challenge themselves in a new environment, and have the orientation to become BrSE/Project Manager.\n\nMembers will learn Japanese for free at the company, then the company will sponsor all study expenses and procedures to study in Japan for one year. After graduating and achieving JLPT N2/N1 level, students will be directly involved in the project as BrSE/Project Managers working directly with Japanese customers. \n\nTGL Go To Japan has successfully held the first 3 seasons and continues to improve the program over the years. All you need to do is to keep your passion and orientation to challenge yourself, and for the training supports or expenses, they will be taken care by TGL Solutions.",
        },
        {
          step: "Intensive training Course, Seminar, Experience sharing Talkshow",
          sub: "TGL Solutions encourages Departments and Units to organize Seminars and Talkshows to share technical knowledge and effective ways of working. In addition, TGL Solutions also invites experts to share knowledge and experiences in work and life.",
        },
        {
          step: "Internal cultural training Course",
          sub: " “Concord among the people” is the core value of TGL Solutions to build a healthy and comfortable working environment for members. On the first day TGL Solutions, members will be introduced to the company’s working culture in detail through the Culture Handbook which is compiled and updated regularly, and also has the opportunity to experience the culture test.\n\nThrough TGL Culture Handbook and tests, you will understand the 3T spirit: Transforming, Trial & Error, and Taking the lead. Not only that, TGL working style: Announcing - Explaining - Discussing is also a factor that makes up the unique identity in the working style of all members of TGL Solutions.",
        },
      ],
    },

    workplace: {
      title: "“Be supportive” working environment",
      sub: "The characteristic of TGL is its youth. You can easily feel the joyful atmosphere, the sociable environment where everyone helps each other for the purpose of “Together We Grow”",
      cards: {
        subOkrs:
          "Each department/unit always sets internal sharing as an important goal in their OKRs.",

        subParty:
          "Sport clubs (soccer, badminton), workshops, monthly Happy Hour and company trip.",

        subHeart:
          "Take care of physical and mental health including annual health check.",

        subEar:
          "Being honest-listening-sharing among employees or between employees and the Board of Directors is always the first criterion that the company aims at.",

        subGroup: "Promote initiatives to develop the company.",
      },
    },

    develop: {
      title: "Sustainable development",
      sub: 'Sustainable development is the destination of TGL Solutions. The "sustainable cycle" is reflected in the company logo and realized in the policies and daily activities. For sustainable development, TGL always takes people as the center in all decisions and actions, focuses on training and developing people, and constantly changing and updating technology trends to meet requirements in the spirit of 3T.',
      conslusion:
        "As a matter of fact, if the employees work more efficiency, the company will undoubtedly grow further. In return, the employees will receive project bonus, pay raise, and other benefits. This will create a sustainable growth for the company, and afterward contributing to the community and society.",
    },

    welcome: {
      title: "OUR MISSION OF ELEVATING VIETNAMESE PEOPLE VALUES!",
      sub: "TGL Solutions is always looking for people who are willing to challenge to create new values.",
      btn: "Together we grow",
    },
  },

  project_detail: {
    title_project_detail: "Projects Details",
    back: "Back",
    not_found: "Not found",
    technical: "Technology:",
    shareLabel: "Sharing from Project Leader",
    listProjectDetails: [
      {
        id: 1,
        title: "Smart AI for diagnosing Idiopathic Pulmonary Fibrosis",
        category: "AI",
        pic: "Nguyen Duy Dat",
        avatar: "/images/members/Dat.jpg",
        tech: "Technologies: Python, Docker, Image Segmentation, Deep Learning",
        message:
          "This project supports early diagnosis of diseases which increases the chances of a patient’s treatment.",
        image: ["/images/ai/1_1.webp", "/images/ai/1_2.png"],
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "What is Idiopathic Pulmonary Fibrosis (IPF)? It’s a condition in which the lungs gradually lose respiratory function and the cause of the disease is unknown.\n IPF is a lung disease that is often fatal and difficult to diagnose, so far there are no accurate data on the number of people affected. As of 2020, there are approximately 180,000 cases of IPF in the United States, and 40,000 people died from the disease each year. In addition, in 2020 in the United States, the number of traffic accident deaths was 38,680 people, which was recorded as the highest level in 13 years, but this number is still lower than the number of people died from the disease.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "Usually, the average survival time of a patient is about 3 years from the time of diagnosis. However, advanced AI technology can diagnose IPF early and accurately. As a result, patients with the disease have more time, more chances for treatment and prolong survival.\nThe algorithm predicts the likelihood of survival, and provides a treatment plan for the patient.",
          },
        ],
      },
      {
        id: 2,
        title: "Smart AI for exterior decoration",
        category: "AI",
        pic: "Nguyen Duy Dat",
        avatar: "/images/members/Dat.jpg",
        tech: "Technologies: Python, Deep Learning, Image Segmentation, Digital Image Processing",
        message:
          "We have developed an automated solution to quickly respond to house exterior decoration demand, which is increasing.",
        image: ["/images/ai/2_1.webp", "/images/ai/2_2.jpg"],
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "Homeowners who want to change the exterior of the house usually follow these steps: \n1. The homeowners take pictures of the exterior of the house and send request .\n2. The architects use Photoshop to edit photos according to the owner’s requirements .\n3. The homeowners view the edited photos and make decision, which usually takes at least 1 day to correct the exterior colors, not to mention the time for meeting in person to discuss. \n⇒ We use AI technology to let owners choose photos and customize the exterior color to their liking on mobile devices.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "The function of recognizing and changing the color of the roof, walls, and household items allows the homeowners to actively change to the color they like, and it only takes about 30 seconds to have the image of the house that they want.",
          },
        ],
      },
      {
        id: 3,
        title: "Smart AI for diagnosing heart diseases by sound",
        category: "AI",
        pic: "Nguyen Duy Dat",
        avatar: "/images/members/Dat.jpg",
        tech: "Technologies: Audio Processing, Auto cut-off frequency, Deep Learning, Audio Classification, Spectrogram, TCP client-sever, Python",
        message:
          "This project helps users to be proactive in checking their health actively through heart rate anytime, anywhere and giving appropriate advice to their health status.",
        image: ["/images/ai/3_1.webp", "/images/ai/3_2.png"],
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "Users can monitor their health at home by recording their heart rate, and the AI system will diagnose heart diseases using the audio file that was sent to the server. From there, customers can actively monitor their health status or get medical support as soon as possible.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "Diagnosing heart rate whether it is normal or abnormal .\n- Giving specific diagnosis of heart disease to the users.\n- Users can actively check their health status.\n- Self-alarm system, which will contact the nearest medical facility when abnormal heart rate is detected, or call an ambulance using GPS tracking.\n - Storing data, which helps doctors quickly view the heart rate history and make appropriate decisions.",
          },
        ],
      },
      {
        id: 4,
        title: "Metaverse project for factory",
        category: "Metaverse",
        pic: "Nguyen Duy Dat",
        avatar: "/images/members/Dat.jpg",
        tech: "Technologies: Omniverse nvidia, Actorcore",
        image: ["/images/ai/4_1.webp", "/images/ai/4_2.jpg"],
        message:
          "Instead of building a real factory, which is very expensive and has many potential risks. Users can simulate a factory in a fully featured virtual space to test its operability at a much lower cost.\nMoreover, you can take a tour of hospitals, schools, factory in virtual space before visiting the real sites.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "Currently, investors and manufacturers spend a lot of time, effort, and money on the installation/manufacturing process. When installing the line, if there are some mistakes in calculation or all cases are not in control, the cost and time spent on these problems will increase a lot.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "The goal of the Digital Twin is to simulate the real world with full operating methods, predicting the risks of the process or the product, thus greatly reducing the time spent on installation/production.",
          },
        ],
      },
      {
        id: 5,
        title: "CAD1 project",
        category: "BIM/CAD",
        pic: "Phan Tran Minh Tri",
        avatar: "/images/members/Tri.jpg",
        tech: "Technologies: C#, WPF",
        image: ["/images/cad/5_1.webp", "/images/cad/5_2.jpg"],
        message:
          "To achieve the success of the project, the most important factor is the success of all members of the team. All we need is detailed plans and the spirit of trying our best for the work and the set goals.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "Based on the strong and outstanding development of the Autocad application, many similar products were developed such as Gcad, BricsCad, and DraftSight to provide a range of functions similar to Autocad but at a cheaper cost. Enterprise customers want to build software for technical support for construction at a reasonable cost to break the CAD monopoly market.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "The system does not only helps analyze, design, and develop commands in CAD drawings, but also optimizes the control bars for simulation and construction of objects such as buildings, and construction materials, etc. with very high accuracy. Thanks to this, the construction or design speed is faster and the work efficiency for the construction industry is improved.",
          },
        ],
      },
      {
        id: 6,
        title: "NewCAD project",
        category: "BIM/CAD",
        pic: "Phan Tran Minh Tri",
        avatar: "/images/members/Tri.jpg",
        tech: "Technologies: .NET, AutoCAD API",
        image: ["/images/cad/6_1.webp", "/images/cad/6_2.jpg"],
        message:
          "This project does not have specific information and is currently under making prototype, so there is not too much information, but this is a very potential project in the future.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "We need an automated development tool that follows a given template and can be flexibly modified according to changing conditions to meet the increasing need for infrastructure design.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "Solution for drawing 3D models for houses, bridges, and roads with high complexity quickly.",
          },
        ],
      },
      {
        id: 7,
        title: "CAD4 project (COM/ActiveX related)",
        category: "BIM/CAD",
        pic: "Nguyen Van Ngoc Minh",
        avatar: "/images/members/Minh.jpg",
        tech: "Technologies: C++, COM/ActiveX",
        image: ["/images/cad/7_1.webp", "/images/cad/7_2.gif"],
        message:
          "COM/ActiveX technology is used to create modules that make the interaction among separate software done in the most conveniently and efficient way.\nIt helps softwares create a public API for other software to interact with them through COM/ActiveX.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "We already had libraries written in C++ that need to be published for other modules written in other languages such as C#, VB.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "These COM/ActiveX modules can operate independently of each other and can also be operated by other modules written in many different and very diverse languages. Through COM/ActiveX, separately developed programs can link and interact in different ways.",
          },
        ],
      },
      {
        id: 8,
        title: "Piping project",
        category: "BIM/CAD",
        pic: "Nguyen Van Ngoc Minh",
        avatar: "/images/members/Minh.jpg",
        tech: "Technologies: ObjectArx, C#",
        image: ["/images/cad/8_1.webp", "/images/cad/8_2.jpg"],
        message:
          "The company has a thorough understanding of the technology of ObjectArx, which can realize ideas and solutions to support AutoCAD works to be done more quickly, efficiently, and accurately, thereby helping to improve work efficiency and bring many benefits to customers.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "The design of the pipeline system for the work created on CAD drawings is still quite difficult and complicated, which will lead to errors in the implementation process.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "Providing additional AutoCAD commands that allow users to create piping systems simpler, faster, and more accurate.\nLink demo video:  https://www.youtube.com/watch?v=Um098tO_u7c&t=1s",
          },
        ],
      },
      {
        id: 9,
        title: "Revit Project",
        category: "BIM/CAD",
        pic: "Nguyen Van Ngoc Minh",
        avatar: "/images/members/Minh.jpg",
        tech: "Technologies: RevitAPI, C#",
        image: ["/images/cad/9_1.webp", "/images/cad/9_2.jpg"],
        message:
          "Revit is a leading software in the field of BIM, which is the future of the construction industry. Mastering the Revit API technology allows the company to fully interact with Revit to create additional parts that will maximize the efficiency of future BIM implementations.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "The setting of metal arrangements for structural elements of the building (pillars, beams, floors, etc.) is done manually which not only takes a long time but there is also a high risk of errors. The simultaneous adjustment will encounter many difficulties.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "Creating features to help users arrange steel for components quickly and accurately. Users only need to enter the necessary parameters, and the software will automatically arrange the steel according to the preset.\nLink demo video: https://www.youtube.com/watch?v=TfuOUMMqvWo",
          },
        ],
      },
      {
        id: 10,
        title: "Web Mirai Project",
        category: "Web System",
        pic: "Nguyen Quy Le",
        avatar: "/images/members/Le.jpg",
        tech: "Technologies: Reactjs, Nextjs, Graphql, TypeOrm, Oracale",
        image: ["/images/web/10_1.webp", "/images/web/10_2.jpg"],
        message:
          "By constantly listening, adjusting and implementing functions, the company wants to bring out the best results to customers through the project.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "Customer wants to build an e-commerce site integrating features such as livestreaming, VR, etc., in order to optimize the company’s sales.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "Features for ordering, buying and selling products, and accumulating points.\nVR Showroom corner, the product introduction video corner of manufacturers.\nLive streaming feature: Live streamers can directly organize and schedule broadcasts. Viewers can watch live broadcasts from sellers and make direct purchases.\nProduct auction feature: Live streamers can host product auctions. Viewers can participate in product auctions, and the winning bidder can place orders and provide payment for the amount of the winning bid.\nAdmin page: Product management, order management, points accumulation, income...; Exporting excel by order,  product, or user...; Creating quick login link to login Top, Admin pages; Sending notifications emails for the order status and the status of points accumulation to the user and the person responsible for the order.",
          },
        ],
      },
      {
        id: 11,
        title: "Lesson Room Project",
        category: "Web System",
        pic: "Phan Dang Lam",
        avatar: "/images/members/Lam.jpg",
        tech: "Technologies: HTML, CSS, JS, WebRTC, Skyway",
        image: ["/images/web/11_1.webp", "/images/web/11_2.jpg"],
        message:
          "The Lesson Room project does not have too many difficult functions, but it is a project that is implemented and developed with many more features from the old source code with many customer errors, so it causes a lot of difficulties for the team to optimize the code and fix old errors. and develop additional features. Although it was a challenge, the team tried their best to complete the project within their ability, to be able to help the customer achieve the desired features.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "Nowadays, online teaching and learning is becoming popular, especially in the context of covid epidemic, that most people are used to learning and working online. Therefore, the client has ordered a classroom website for the purpose of teaching and learning online.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "1/ 1 on 1 study between teacher and student through video call\n2/ Real-time textbook learning materials sharing\n3/Whiteboard feature helps teacher and student interact with each other as a real class\n4/ Chating and sending file feature\n5 / Note feature\n6/ Sharing screen feature\n7/ Recording screen feature\n8/ Background filter feature",
          },
        ],
      },
      {
        id: 12,
        title: "YHK Project",
        category: "Web System",
        pic: "Phan Ngoc Thinh",
        avatar: "/images/members/Thinh.jpg",
        tech: "Technologies: React, Ant Design, C# .NET, RESTful API",
        image: ["/images/web/12_1.webp", "/images/web/12_2.jpg"],
        message:
          "TNF team is pleasure to be in charge of the Core System project named YHK. During the project, our team also learned and accumulated a lot of experience, which is a great base to carry out larger projects in the future.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "YHK is a centralized management system, which effectively supports operations related to warehouse management. The client wants to use YHK to replace most manual work to get the job done faster and easier.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "Optimizing order process, warehousing, and shipping.\nAutomatically issuing delivery notes.\nAutomatically creating and sending periodic reports",
          },
        ],
      },
      {
        id: 13,
        title: "Spotlight Project",
        category: "Web System",
        pic: "Phan Ngoc Thinh",
        avatar: "/images/members/Thinh.jpg",
        tech: "Technologies: NextJS, Laravel, WebRTC",
        image: ["/images/web/13_1.webp", "/images/web/13_2.jpg"],
        message:
          "This is one of the interesting and very potential projects in the future. The current video call system is very popular with many platforms and is applied in the context of society using the internet to exchange information with each other. And in the future, the system will expand and develop more and more to meet all of our requirements.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "Spotlight is a video call system between two users with a variety of devices. As a cost-effective solution for businesses,  it provides the most efficient and easy call management and features.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "Used for video calls between web browsers; web browsers and web applications; and between web applications.\nBeing able to exchange data between two or more devices without using an intermediary server.\nTo create a WebPhone used for web conferencing, customer support, online tutorials, games, etc.\nNo charge for communication services, etc., from the customer or company side.\nScreen sharing function allows you to support your customers easily and securely.\nSpotlight system developed by skyACE is a typical WebRTC (business phone system) that can connect directly with users on a web browser.",
          },
        ],
      },
      {
        id: 14,
        title: "Girls Bar Project",
        category: "Web System",
        pic: "Phan Ngoc Thinh",
        avatar: "/images/members/Thinh.jpg",
        tech: "Technologies: DB: Aurora\nLaravel-ver9, PHP-ver8.1, Bootstrap CSS\nNGINX",
        image: ["/images/web/14_1.webp", "/images/web/14_2.jpg"],
        message:
          "This is a project that is very interesting both in terms of content and technology.\nAbout the content, through this project we have the opportunity to learn more about Japan, and about unique services like Japanese Girl’s bar.\n About the technology, this is the first time we have used Lavarel to do a real project. Although there are still many new things, it is also a great motivation for us to develop more and more, pioneering new technologies as our team name TNF - To New Future.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "The Girl’s bar is a restaurant run by a female bartender in charge of preparing and receiving guests. Guests can sit at the bar to talk and ask the bartender to make drinks for them. In Japan, Girl’s Bar is a type of service that is quite popular and imbued with Japanese culture. Realizing that the job connection between people finding jobs and bars hiring staff today is not effective, we helped customers develop a product called GirlsMeee - The special connection system for this type of service.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "For candidates: GirlsMeee allows candidates to find jobs that are convenient for their commute. In addition, those who have worked at Bars can also leave reviews for other candidates to refer to .\nFor employers: Bars can post jobs and search for the most suitable candidates for the job.",
          },
        ],
      },
      {
        id: 15,
        title: "Kensa Cloud App",
        category: "Smartphone App",
        pic: "Phan Ngoc Thinh",
        avatar: "/images/members/Thinh.jpg",
        tech: "Technologies: React, React Native, Ant Design, TailwindCSS, .NET",
        image: ["/images/app/15_1.webp", "/images/app/15_2.jpg"],
        message:
          "This is one of the first products for our ambition to enter the Product field. Although it is only a product of not too large scale, it has the spirit of 3T: Transforming - Trial and Error - Taking the lead. We will do our best to make this product achieve great achievements in the near future.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "Normally, it takes a lot of time and effort to conduct a survey of housing inspection projects, take photos to summarize the results and create the report.\n- The acceptance work between the investors, who put money into real estate, and the construction company is still lacking transparency, and the management is also ineffective.\n- The field implementation lacks visual comparison, wmaking it difficult to assess the quality of the works.\n- The system for storing and synchronizing data after acceptance between the parties is not consistent and professional.\n- It is difficult to track real-time construction and productivity data.\nFrom those issues, we created the Kensa Cloud system in order to solve them as much as possible. This is an inspection app that allows you to easily conduct inspections on your smartphone and generate reports automatically.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "The acceptance of works can be done quickly and easily with just one phone (online or offline), optimizing productivity.\nPromoting transparency in the acceptance process, directly improving product quality.\nCreating an optimal working environment between inspectors and administrators.\nAutomatically generating reports and sending results to the cloud just by using a button, followed by the inheritance and customization features.",
          },
        ],
      },
      {
        id: 16,
        title: "BLE App",
        category: "Smartphone App",
        pic: "Huynh Huy Tuan",
        avatar: "/images/members/Tuan.jpg",
        tech: "Technologies: Flutter, Sqlite, BLE, IOT",
        image: ["/images/app/16_1.webp", "/images/app/16_2.jpg"],
        message:
          "With our knowledge and experience, we hope to provide technology solutions to reduce human effort. Customer satisfaction is the happiness and motivation for us to improve and develop our products.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "In the culinary industry, measuring and managing the temperature of food and beverages is very important. However, it takes effort to monitor continuously to avoid unexpected problems such as too high or too low temperature, faulty measuring devices or batteries, etc.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "Bluetooth application combined with wireless and sensor Chino MF100 will be a great solution for long-term temperature monitoring.\nThe application includes the following main functions: \n- Monitoring and displaying the temperature of multiple sensors in real-time.\n- Managing connection with sensors.\n- Detail statistics of measurement results by date, and time.\n- Announcing by sound or sending email when there is anomaly. \n- Setting parameters for anomalies such as limit temperature, sensor error, etc.",
          },
        ],
      },
      {
        id: 17,
        title: "EPCO App",
        category: "Smartphone App",
        pic: "Phan Huynh Minh Duy",
        avatar: "/images/members/Duy.jpg",
        tech: "Technologies: React Native, .NET Core, MySQL, Firebase",
        image: ["/images/app/17_1.webp", "/images/app/17_2.png"],
        message:
          "Although most of the project team members have little experience (almost no experience at the time of joining), they have tried to develop and fulfill their role in the project well. The team always tries to be proactive at work and is willing to absorb new knowledge to apply to themselves and their projects, bringing value to customers.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "The client (real estate company) needs a smartphone application to serve the purpose of communication between customers and their employees, and will gradually develop more features in the future.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "- Communication between customers and staff (including sending documents, photos, and videos).\n- Creating periodic repair/inspection requests for the house.\n- Displaying information, instructions, and some other features.\n- Implementing some features for Security Reinforcement, ensuring high security for the project.\nDesigning quickly mobile apps on both iOS and Android platforms, helping businesses save time, effort, and cost for development and maintenance.",
          },
        ],
      },
      {
        id: 18,
        title: "Ikkanka Project",
        category: "PC Application",
        pic: "Nguon Man",
        avatar: "/images/members/Man.jpg",
        tech: "Technologies: C# .NET (WPF MVVM), .NET CORE (Oracle)",
        image: ["/images/app/18_1.webp", "/images/app/ikkanka_1.jpg"],
        message:
          "The Industry 4.0 era where all industries apply technology, forcing businesses to change to catch up with the development of the economy. Everything can be improved and upgraded (increasing human resources, upgrading equipment, ...). However, time is limited. Therefore, saving time as much as possible is the best solution.",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "In the Industry 4.0 era, everything has to be digitized, in which logistics is an extremely important factor to help businesses save effort and time, thereby improving productivity/work quality.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "Providing customers with logistics solutions to facilitate the ordering and delivery of goods quickly and efficiently (optimizing distance, time, and effort).",
          },
        ],
      },
      {
        id: 19,
        title: "Shuho Project",
        category: "PC Application",
        pic: "",
        avatar: "/images/members/penguin.png",
        image: ["/images/app/19_1.webp", "/images/app/suho_1.jpg"],
        tech: "Technologies: C# .NET (WPF MVVM), .NET CORE (Oracle)",
        message: "",
        content: [
          {
            title: "1. BACKGROUND & ISSUES:",
            content:
              "To create a weekly report, the customer must go to the website to analyze the data, then make a report and perform manual operations such as creating a summary excel file: tables, graphs, etc., depending on the data, print the report, and hand it to the superior. Therefore, they wanted to create a PC application to optimize weekly reports and have auto-update features as a web application.",
          },
          {
            title: "2. FEATURES & SOLUTION:",
            content:
              "The project helps to optimize report generation time from 3 hours to 10 minutes with the following features :\n- Supporting to automatically collect the most suitable data for weekly reports.\n- Creating a clear report file due to being able to deeply intervene in the excel file, synthesizing and exporting the report file to excel.",
          },
        ],
      },
    ],
  },

  products: {
    webCloud: {
      title: "Web Cloud System",
      items: [
        {
          content: "Work Bee - Lean digital transformation platform",
          link: "https://work-bee.net/",
        },
        {
          content:
            "Onsite System - Comprehensive construction project management",
          link: "https://bimcad-vietnam.com/vi/construction-software/vinacad",
        },
        {
          content: "Onsiter Cloud CDE on Cloud",
          link: "https://bimcad-vietnam.com/bim-viewer/bim-web-viewer",
        },
      ],
    },
    bimCad: {
      title: "CAD/BIM Software",
      items: [
        {
          content: "VinaCAD - Vietnam's No. 1 CAD Software",
          link: "https://vina-cad.com/",
        },
        {
          content: "VinaBIM - Project maintenance and operation",
          link: "https://vina-cad.com/",
        },
        {
          content: "VinaQ - Volumetric Analysis",
          link: "https://vina-cad.com/",
        },
        {
          content: "TakaCAD - 2D structural drawing implementation",
          link: "https://vina-cad.com/request-code?product=takacad",
        },
        {
          content: "BrightBIM - Steel layout plugin for Revit",
          link: "https://vina-cad.com/request-code?product=brightbim",
        },
      ],
    },
  },

  recruiment: {
    banner: {
      title: "Let’s start your journey with TGL Solutions!",
      sub: 'TGL Solutions is building an ideal environment where everyone is encouraged to create great values, bring about positive change for the company’s sustainable development and contribute to the society development.\n\nAt TGL Solutions, we are always looking for people who dare to change, dare to challenge to realize great ideals of elevating Vietnamese people values. If you are confident that you can become the next generation of "The First Penguin", don’t hesitate to join the TGL team so that together we will reach new levels.',
    },

    jobOpenings: {
      title: "Opening jobs",
      btnShowAll: "See all the opening jobs",
      listjob: [
        {
          id: 1,
          level: "From 3 years of experience",
          position: "Japanese Bridge Software Engineer",
          sub: "Competing",
          extra: "Flexibility",
          content: [
            {
              title: "JOB DESCRIPTION",
              description:
                "***Note: This position is recruiting candidates for the Vietnam branch and candidates in Japan for the parent company in Japan.\n　• Working with Japanese customers to receive requirements from customers, meeting with customers to report/discuss project progress.\n　• Consulting and discussing with customers to choose technology + solutions for the project.\n　• Implementing requirement and basic design for projects with many different domains (there will be Tech Lead for each supporting domain).\n　• Supervising and managing the progress of projects, conducting Q&A exchanges directly with customers, and communicating with the project development team\n　• For candidates working in Japan: Supporting management / guiding other bridge software engineers who lack experience in managing and controlling the quality of the company’s projects. (for candidates with a lot of experience as BrSE Leader/Delivery Manager).",
            },
            {
              title: "JOB REQUIREMENTS",
              description:
                "　• Bachelor’s degree in IT/Computer Science or related profession.\n　• Experience directly participating in projects as a bridge software engineer/project manager for ~3 years or more.\n • Japanese N1 or equivalent.\n　• Ability to analyze requirements and build systems.\n　• Leadership skills are an advantage.\n　• High sense of responsibility in work.",
            },
            {
              title: "BENEFIT",
              description:
                "- Salary:\n　• 1-month probation with 100% salary\n　• Salary increase 2 times/year (Salary increase apply immediately in June & December of the year)\n\n- Bonus: \n　• For members: Bonus 2 times/year: maximum 0.5 month salary in June + 1 month salary in December (the coefficient is based on new salary).\n　• For managers: Bonus 2 times/year: up to 1 month salary in June + up to 2 months salary in December (the coefficient is based on new salary).\n\n- Other benefits: \n　• 13 days OFF/year.\n　• Football match once a week; Esport activities; Team Building; Birthday party, company trip\n　• Presents for birthdays, holidays, New Year,…\n　• Other benefits according to the Law.\n　• Free snacks, coffee, tea…\n\n- Education benefits:\n　• Foreign language allowance, course allowance and award for certificates and international degrees\n　• Free Udemy Course; foreign language classes; seminars, talk shows to share experiences; direct training lessons with experts\n　• TGL Go To Japan Program – Study and work in Japan\n　• English and Japanese classes at the office.\n\n- Environment working:\n　• Flexible working time (working from Monday to Friday), WFH 5 days/month\n　• Young, comfortable working environment with mutual supportive colleagues\n　• The company always promote the spirit of learning & creates all conditions for employees to develop their strengths and orient their careers.",
            },
          ],
        },
        {
          id: 2,
          level: "All levels",
          position: "LTV .NET C#",
          sub: "Competing",
          extra: "Flexibility",
          content: [
            {
              title: "JOB DESCRIPTION",
              description:
                "- Main content:\n　• Developing commands according to requirements definition documents (document, specification)\n　• Refer to attached project video: \nhttps://www.youtube.com/watch?v= opHXXkdOC5w&list=PLYWZmjpuHQMxs-FMWjBpNYmxvDqAQNA7E\n\n- Why should we join to project?\n　• Trained in logical thinking about Code: Coding in the most logical, scientific and understandable way\n　• Participated in the Comprehensive software development process: from initial requirements analysis to product deployment\n　• Learning many development techniques and code design rules.\n　• Practicing your working flow, the way to process & manage documents, and effective teamwork: Communicate with Comtors/ Testers/Team Leader/Project Leader and customers\n\n- Job Description:\n　• Developing commands according to requirements definition documents (document, specification)\n　• Researching, designing, estimating completion time and assigned coding command\n　• Self-checking/ Unit Testing according to Spec document available\n　• Update completed code logic based on Feedback from Senior/ Project Leader\n　• Fixing bugs reported from Tester and Customer\n　• Completing assigned command and reporting to Project Leader\n　• Joining Meeting weekly to report progress, difficulties and plans\n　• Doing tasks asigned by TL/.DM according to Smart and Speed criteria.",
            },
            {
              title: "JOB REQUIREMENTS",
              description:
                "　•	Bachelor’s degree in IT/Computer Science or related\n　• Mastering OOP object-oriented programming\n　• Experience in C# programming language\n　• Able to build and design forms using WPF \n　• Good skills in data structures and algorithms\n　• Logical thinking and good problem-solving ability\n　• Able to work in groups and independently\n　• Inquisitive spirit solve problems to the end, high responsibility in work.",
            },
            {
              title: "BENEFIT",
              description:
                "- Salary:\n　• 1- month probationary with 100% salary\n　• Salary increase 2 times/year (Salary increase applies immediately in June & December of the year)\n\n- Bonus: \n　• Bonus 2 times /year: maximum 0.5 month salary in June + 1 month salary in December.  (the coefficient is based on new salary) .\n\n- Education benefits:\n　• Foreign language allowance, course allowance and award for certificates and international degrees\n　• Free Udemy Course; foreign language classes; seminars, talk shows to share experiences; direct training lessons with experts\n　• TGL Go To Japan Program – Study and work in Japan\n　• English and Japanese classes at the office.\n\n- Environment working:\n　• Flexible working time (working from Monday to Friday), WFH 5 days/month\n　• Young, comfortable working environment with mutual supportive colleagues\n　• Company always promote the spirit of learning & create all conditions for employees to promote their strengths and orient their careers.",
            },
          ],
        },
        {
          id: 3,
          level: "No experience required",
          position: "LTV C++",
          sub: "Competing",
          extra: "Flexibility",
          content: [
            {
              title: "JOB DESCRIPTION",
              description:
                "- Main content:\n　• Creating APIs For CAD system based on ODA platform (in C++/CLI) which allows further development of commands using these APIs.\n　• Developing commands according to requirements definition documents (document, specification) \n\n- Why should we join to project?\n　• Trained in logical thinking about Code: Coding in the most logical, scientific and understandable way\n　• Participated in the Comprehensive software development process: from initial requirements analysis to product deployment\n　• Learning many development techniques and code design rules.\n　• Practicing your working flow, the way to process & manage documents, and effective teamwork: Communicate with Comtors/ Testers/Team Leader/Project Leader and customers\n\n- Job Description:\n　• Developing APIs according to the requirements definition documents (document, specification) \n　• Researching, designing, estimating completion time and assigned coding command\n　• Self-checking/ Unit Testing according to Spec document available\n　• Update completed code logic based on Feedback from Senior/ Project Leader\n　• Fixing bugs reported from Tester and Customer\n　• Completing assigned command and reporting to Project Leader\n　• Joining Meeting weekly to report progress, difficulties and plans\n",
            },
            {
              title: "JOB REQUIREMENTS",
              description:
                "　• Bachelor’s degree in IT/Computer Science or related\n　• Fluently using C/C++ programming language.\n　• Knowledge of OOP object-oriented programming, working with libraries ObjectArx library is an advantage.\n　• Good skills in data structures and algorithms\n　• Logical thinking and good problem solving\n　• Able to work in groups and work independently \n　• Spirit of exploring to solve problems to the end, high responsibility at work.",
            },
            {
              title: "BENEFIT",
              description:
                "- Salary:\n　• 1- month probationary with 100% salary\n　• Salary increase 2 times/year (Salary increase applies immediately in June & December of the year)\n\n- Bonus: \n　• Bonus 2 times /year: maximum 0.5 month salary in June + 1 month salary in December.  (the coefficient is based on new salary) .\n\n- Education benefits:\n　• Foreign language allowance, course allowance and award for certificates and international degrees\n　• Free Udemy Course; foreign language classes; seminars, talk shows to share experiences; direct training lessons with experts\n　• TGL Go To Japan Program – Study and work in Japan\n　• English and Japanese classes at the office.\n\n- Environment working:\n　• Flexible working time (working from Monday to Friday), WFH 5 days/month\n　• Young, comfortable working environment with mutual supportive colleagues\n　• Company always promote the spirit of learning & create all conditions for employees to promote their strengths and orient their careers.",
            },
          ],
        },
        {
          id: 4,
          level: "Job requires from 1 years of experience",
          position: "LTV BACK - END (NODEJS)",
          sub: "Competing",
          extra: "Flexibility",
          content: [
            {
              title: "JOB DESCRIPTION",
              description:
                "- Opportunities when participating in the project :\n　1. The project development process follows the Agile model, working with CI/CD, AWS\n　2. Participating in projects with many different BE languages: C#, Nodejs, PHP (according to your demand) to gain more experience\n　3. Directly participating in the software development process from the beginning to understand Business, designing data structures\n \n- Job Description:\n　• Joining the Cloud Solutions Department - develop the company’s website with diverse domains: E - com, business system, AR, live stream, educational web…\n　• Responsible for developing the website as the Back-end part, analyzing, handling and solving system problems of the project.\n　• Cross-code reviewing among other team members.\n　• Writing related documents.\n　• Collaborate with team members to make products with high quality.\n　• Collaborating with front-end developers and web designers to analyze design requirements and develop features and deploy products according to customer requirements\n　• Supporting new members, less experienced members of the project team (for members who already have skills)\n　• Exchanging and discussing with superiors to give directions for project development and team development. \n　• Attending regular project meetings, weekly department meetings",
            },
            {
              title: "JOB REQUIREMENTS",
              description:
                "　• Have experience working with NodeJS technology (knowing NestJS is an advantage)\n　• Experience using Cloud services like AWS (or Microsoft Azure, Google Cloud Platform, ...)\n　• Being proficient in writing Restful API, knowing GraphQL is an advantage　• Experience in CSDL such as Oracle SQL, MySQL, SQL Server\n　• Understanding Object Relational Mapping (ORM) and working with these libraries such as TypeOrm, Entity Framework,...\n　• Configure web servers such as IIS, Nginx and support tools such as Visual Studio, Visual StudioCode, GIT, Sourcetree\n　• Ability to think logically, problem-solving, teamwork",
            },
            {
              title: "BENEFIT",
              description:
                "- Salary:\n　• 1- month probationary with 100% salary\n　• Salary increase 2 times/year (Salary increase applies immediately in June & December of the year)\n\n- Bonus: \n　• Bonus 2 times /year: maximum 0.5 month salary in June + 1 month salary in December.  (the coefficient is based on new salary) .\n\n- Education benefits:\n　• Foreign language allowance, course allowance and award for certificates and international degrees\n　• Free Udemy Course; foreign language classes; seminars, talk shows to share experiences; direct training lessons with experts\n　• TGL Go To Japan Program – Study and work in Japan\n　• English and Japanese classes at the office.\n\n- Environment working:\n　• Flexible working time (working from Monday to Friday), WFH 5 days/month\n　• Young, comfortable working environment with mutual supportive colleagues\n　• Company always promote the spirit of learning & create all conditions for employees to promote their strengths and orient their careers.",
            },
          ],
        },
        {
          id: 5,
          level: "Job requires from 0 ~ 12 months of experience",
          position: "LTV MOBILE",
          sub: "Competing",
          extra: "Flexibility",
          content: [
            {
              title: "JOB DESCRIPTION",
              description:
                "• Joining the smartphone app development team.\n　• Participating in the project from the beginning, analyzing requirements, designing, coding, and deploying the product.\n　• Getting tasks, estimating completion time, coding, self-testing, and deploying the product.\n　• Attending daily/weekly eetings to report progress and discuss project-related issues.",
            },
            {
              title: "JOB REQUIREMENTS",
              description:
                "　•Bachelor’s degree in IT/Computer Science or related program\n　• Knowledge/experience in React Native.\n　• Knowledge of .NET backend is an advantage.\n　• Advice Logical thinking and good problem-solving ability.\n　• Able to work in groups and independently.\n　• The spirit of exploring to solve problems to the end, high responsibility in work.",
            },
            {
              title: "BENEFIT",
              description:
                "- Salary:\n　• 1- month probationary with 100% salary\n　• Salary increase 2 times/year (Salary increase applies immediately in June & December of the year)\n\n- Bonus: \n　• Bonus 2 times /year: maximum 0.5 month salary in June + 1 month salary in December.  (the coefficient is based on new salary) .\n\n• Bonus according to the project (depending on the project).\n　• Excellent Individual/Team bonus 4 times/year.\n　• Year-end special bonus: 60 million VND/person (for the 3 best members).\n\n- Other benefits: \n　• 13 days OFF/year.\n　- Education benefits:\n　• Foreign language allowance, course allowance and award for passing certificates and international degrees\n　• Free Udemy Course; foreign language classes; seminars, talkshows to share experiences; invite experts to train directly at the company\n　• TGL Go To Japan Program – Study and work in Japan\n　• English and Japanese classes directly at the company.\n\n- Environment working:\n　• Flexible working time (working from Monday to Friday), WFH 5 days/month\n　• Young, comfortable working environment and mutual supportive colleagues\n　• Company always promote the spirit of learning & create all conditions for employees to promote their own strengths and orient their careers.",
            },
          ],
        },
      ],
    },

    keyBenefit: {
      title: "The benefit of becoming a Penguin",
      cards: {
        internal: {
          title: "Internal training",
          sub: 'With the vision of "Elevating the Vietnamese people values", TGL Solutions always focuses on internal training activities, organizes talk shows, and seminars to share experiences, and invite experts to train directly at the company. ',
        },
        hours: {
          title: "Flexible working time",
          sub: "Work flexibly according to your own schedule.",
        },
        salary: {
          title: "Attractive salary",
          sub: "Salary increase 2 times/year and bonus 2 times/year.",
        },
        activity: {
          title: "Company activities",
          sub: "Men’s football club, monthly Happy Hour, company trip, periodic health check.",
        },
        workhome: {
          title: "Work from home",
          sub: "Each employee has 5 days a month to work from home.",
        },
        environment: {
          title: "Healthy environment",
          sub: "Respect - Sociability - Comfortable are three things you will experience every day at TGL Solutions.",
        },
      },
    },

    poem: { title: "Working fun makes working well." },

    welcome: {
      title: "LET’S ELEVATE VIETNAMESE PEOPLE VALUES TOGETHER!",
      sub: "TGL Solutions is always looking for people who are passionate about challenges to create value.",
      btn: "Together we grow",
    },

    detailJobs: {
      benefitPackage: [
        {
          title: "Benefits",
          sub: null,
        },
        {
          title: "Mental health care",
          sub: [
            "Organizing periodic health check",
            "Preparing presents on special occasions",
            "Encouraging participation in sports activities",
          ],
        },
        {
          title: "Learning and training",
          sub: [
            "Organizing weekly seminars",
            "Encouraging to challenge in any job position",
          ],
        },
        { title: null, sub: [] },
        {
          title: "Events",
          sub: [
            "Organizing monthly birthday party and Happy Hour for members",
            "Providing budget for Team activities",
            "Organizing company trip and Year End Party",
          ],
        },
        {
          title: "Awards",
          sub: [
            "Organizing quarterly TGL Spot Award to reward excellent teams",
            "Organizing TGL Award for 3 excellent individuals and 1 team at the end of the year",
          ],
        },
        { title: null, sub: [] },
        { title: null, sub: [] },
        {
          title: "Facilities",
          sub: [
            "Ensuring coffee, confectionery available at the office",
            "Providing working equipments",
            "The company’s office is located in the central area of Ho Chi Minh City with many restaurants which is convenient to commute.",
          ],
        },
      ],

      similarOpportunities: {
        title: "Other Positions",
        cards: [
          {
            title: "Front-end Engineer",
            position: "Junior",
            sub: "Competing",
          },
          {
            title: "Front-end Engineer",
            position: "Junior",
            sub: "Competing",
          },
          {
            title: "Front-end Engineer",
            position: "Junior",
            sub: "Competing",
          },
        ],
      },
    },
  },

  contact: {
    title: "Contact us!",
    sub: "Please fill in the following information so that TGL has the opportunity to contact with you. Our Penguins will respond to you as soon as possible.",
    welcome: "Join TGL SOLUTIONS to be a pioneer penguin in creating value!",
    cards: [
      {
        title: "Address ",
        sub: [
          "5th floor, Bcons Tower II, 42/1 Ung Van Khiem St, Ward 25, Binh Thanh District, Ho Chi Minh City",
        ],
      },
      {
        title: "Business Hours ",
        sub: ["Monday - Friday: 8:30-18:00", "Flexible working time"],
      },
      {
        title: "Phone",
        sub: ["+84-377-359-728"],
      },
      {
        title: "Email",
        sub: ["hr@tgl-sol.com"],
      },
    ],
    dataFormMail: {
      title: "Contact us",
      subTitle:
        'With the motto "Building a strong and people-centric company", all policies and decisions of the company are aimed at building the most comfortable working environment, creating all conditions for employees to promote their potential, personal strengths, and career orientation.',
      name: "Full Name",
      email: "Email",
      phone: "Phone number",
      skills: "Skills",
      birthday: "Birthday",
      website: "Website ...",
      network: "Facebook, LinkedIn ...",
      other: "Others",
      message: "Your message",
      upload_file: "File",
      upload_cv: "+ Upload file",
      content_cv:
        "Upload 1 file in .PDF/.DOC/.DOCX/.JPG/.PNG/.ZIP format with a size of no more than 10MB/ file.",
      submit: "Submit",
      status: "Status",
      success: "Successfully!",
      fail: "Error!",
      file_wrong: "Only pdf, doc, docx formats are accepted.",
      file_success: "The file has been uploaded successfully",
      file_size:
        "File size is too large. Please choose files smaller than 10MB",
      required: {
        name: "Please enter your name",
        error_name: "Name refers to alphanumeric characters and spaces",
        email: "Please enter your Email",
        phone: "Please enter your phone number",
        cv: "Required CV",
        error_number: "Number must be from 10 to 12 digits",
        error_email: "Please enter a valid Email",
      },
      other_file: "+ Upload other files",
      other_file_list: "List file:",
      delete: "Delete",
    },
  },

  footer: {
    award: {
      title: "Quality & Security",
      description: "are our top priorities",
    },
    contents: [
      {
        title: "Office",
        content11: "5th floor, Bcons II Tower",
        content12:
          "42/1 Ung Van Khiem St, Ward 25, Binh Thanh District, Ho Chi Minh City",
        ref1: "https://www.google.com/maps/place/TGL+Solutions/@10.803317,106.7129046,17z/data=!3m1!4b1!4m5!3m4!1s0x3175296c9e4131e9:0xf693c3afeb7bfe46!8m2!3d10.8033117!4d106.7150933",
      },
      {
        title: "Contact",
        content1: "hr@tgl-sol.com",
        ref1: "mailto:hr@tgl-sol.com?subject=subject&body=body",
        content2: "028 2200 2019",
        ref2: "tel: 02822002019",
      },
      {
        title: "SNS",
        content1: "TGL Solutions",
        ref1: "https://www.facebook.com/TGLSolutions",
        content2: "https://tgl-sol.com",
        ref2: "/",
        content3: "TGL TV",
        ref3: "https://www.youtube.com/channel/UCyhiHfIqwnXJhoGVKmlUEPA",
      },
    ],
  },

  detailJobs: {
    salary: "Mức lương",
    experience: "Kinh nghiệm",
    level: "Vị trí",
  },
};

export default vn;
